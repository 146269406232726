import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput,SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import CurrencyInput from '../../components/moneyInput';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from '../layouts/Buttons'
import {differenceInDays} from 'date-fns'

import { format } from 'date-fns'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalBillsToPayDown({showModal, handleCloseModal, handleReload, data}) {
    const [loading, setLoading]                       = useState(false)

    const [people,setPeople]                          = useState('')
    const [amount,setAmount]                          = useState(0.00)
    const [paymentcondition,setPaymentCondition]      = useState('')
    const [idkindofpayment,setIdKindOfPayment]        = useState('')
    const [comments ,setComments]                     = useState('')
    const [invoice ,setInvoice]                       = useState('')
    const [fees, setFees]                             = useState(0)
    const [fine, setFine]                             = useState(0)
    const [amountToPay,setAmountToPay]                = useState(0)
    const [amountPay,setAmountPay]                    = useState(0)
    const [daysOfDelay, setDaysOfDelay]               = useState(0)
    const [payDay, setPayDay]                         = useState('')
    const [dataKindOfPayment, setDataKindOfPayment]   = useState([])
    const [status, setStatus]                         = useState('')
    const [duedate, setDueDate]                       = useState('')
    const [statusInvoice, setStatusInvoice]           = useState(-1)
    const [statusActual, setStatusActual]             = useState('')      
    const [className, setClassName]                   = useState('')  
    const [dataStatus, setDataStatus]                 = useState([])

    const handleStatus = (_amountToPay, _idstatus, _amount) => {
        setAmountToPay(_amountToPay)

        _amountToPay = _amountToPay.replace('.','').replace('.','').replace('.','').replace(',','.')
        _amount      = _amount.replace('.','').replace('.','').replace('.','').replace(',','.')

        if ([0,1,6].includes(_idstatus)) {
            if (parseFloat(_amount) !== parseFloat(_amountToPay)) {
                setStatus('Fatura Baixa Parcialmente')
            }
        }
        if ([3].includes(_idstatus)) {
            if (parseFloat(_amount) === parseFloat(_amountToPay)) {
                setStatus('Fatura Baixada Totalmente')
            }
        }
        if ([0,1,6].includes(_idstatus)) {
            if (parseFloat(_amount) === parseFloat(_amountToPay)) {
                setStatus('Fatura Baixada')
            }
        }

        if (parseFloat(_amountToPay) > parseFloat(_amount)) {
            setStatus('Valor Maior')
        }
    }
    
    const setData = (obj)=> {
        if (JSON.stringify(obj) === '{}')
            return

        setLoading(true)
        setPeople(obj.name)
        let _amount = 0
        if (obj.amountpaid > 0)
            _amount =  (obj.amount - obj.amountpaid).toFixed(2).toString().replace('.',',')
        else 
            _amount =  obj.amount.toFixed(2).toString().replace('.',',')

        setAmount(obj.amount.toFixed(2).toString().replace('.',','))
        setAmountPay(obj.amountpaid.toFixed(2).toString().replace('.',','))
        setDaysOfDelay(differenceInDays(new Date(obj.duedate),new Date(),'yyyy-MM-dd'))
        setPayDay(format(new Date(),'yyyy-MM-dd'))
        setStatusInvoice(obj.idinvoicestatus)
        setDueDate(format(new Date(obj.duedate),'dd/MM/yyyy'))
        setStatusActual(obj.invoicestatus)
        let _class = obj.classname.split('-')[1]
        setClassName(_class)
        setPaymentCondition(obj.paymentcondition)
        setIdKindOfPayment(obj.idkindofpayment)
        setComments(obj.comments)
        setInvoice(obj.document)
        handleStatus(_amount,obj.idinvoicestatus, _amount)
        setLoading(false)
    }

    const getData = async() => {
        setLoading(true)
        await api.get('/getBillsToPayData')
                 .then((response) => { 
                    setDataKindOfPayment(response.data.kindofpayment)
                    setDataStatus(response.data.invoiceStatus)
                    setLoading(false)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
    }

    const handleSubmit = async(event) => {
        event.preventDefault()

        let _amount      = parseFloat(amount.toString().replace(',','.')).toFixed(2)
        let _amountPay   = parseFloat(amountPay.toString().replace(',','.')).toFixed(2)
        let _amountToPay = parseFloat(amountToPay.toString().replace(',','.')).toFixed(2)
  
        if (_amountToPay > (_amount - _amountPay)) {
            ShowMessages({message : 'Valor informado e maior que o valor a pagar!',status : 500})
            return
        }

        
        loadingButton(true,document.querySelector('#submit-billstopay'),document.querySelector('#submit-billstopay').children[0].classList,document.querySelector('#submit-billstopay').lastChild.nodeValue)
        try {
            let _data = {amountToPay,
                         idkindofpayment,
                         fees,
                         idinstallmentpayable : data.idinstallmentpayable,
                         fine,
                         payDay,
                         comments }

            await api.post('/bills-to-pay/down/', _data)
                     .then(response => {
                          ShowMessages(response)
                          if (response.ok) {   
                             if (response.data.status === 200) {
                                 handleCloseModal()  
                                 handleReload(true)    
                             } else 
                                 loadingButton(false,document.querySelector('#submit-billstopay'),null,'Baixar')        
                          } else
                             loadingButton(false,document.querySelector('#submit-billstopay'),null,'Baixar')
                       })
                       .catch( error => {
                         loadingButton(false,document.querySelector('#submit-billstopay'),null,'Baixar')
                         ShowMessages(error) 
                       })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-billstopay'),null,'Baixar')
            ShowMessages(response)
        }
    }
       
    useEffect(() => {
        getData()
        setStatistic('modals\\modal-billstopay')
    } ,[])

    useEffect(() => {
        setData(data)
    } ,[showModal])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-money-bill-wave"></i> Baixar Parcela de Contas a Pagar
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth330px"/>
                    <div className={`${loading ? "hidden" : ""}`}>               
                        <div style={{display:"flex"}}>
                            <div style={{width:"55%"}}>
                                <label>Fornecedor</label>
                                <div className="input-group">
                                    <TextInput 
                                        name="people" 
                                        id="people" 
                                        disabled
                                        className="form-control "
                                        value={people} 
                                        onChange={e => setPeople(e.target.value)}/> 
                                </div>
                            </div>  
                            <div style={{marginLeft:"15px",width:"42%"}}>
                                <label>Status Atual</label>
                                <div className={`bg-${className}`} style={{padding: "5px", borderRadius: "5px", textAlign: "center"}}>
                                    {statusActual}
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"50%"}}>
                                <label>Condições de Pagamento</label>
                                <div className="input-group">
                                    <TextInput 
                                        name="idpaymentcondition" 
                                        id="idpaymentcondition" 
                                        placeholder="Selecione" 
                                        disabled
                                        className="form-control "
                                        value={paymentcondition} 
                                        onChange={e => {setPaymentCondition(e.target.value)}}/> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",width:"30%"}}>
                                <label>Espécie de Pagamento</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="idKindOfPayment" 
                                        id="idKindOfPayment" 
                                        placeholder="Selecione" 
                                        required
                                        errorMessage={{ required: "Por favor, informe uma espécie de pagamento!" }}
                                        className="form-select"
                                        value={idkindofpayment} 
                                        onChange={e => {setIdKindOfPayment(parseInt(e.target.value))}}>
                                            <option value="">Selecione</option>
                                            {dataKindOfPayment.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                    </SelectGroup> 
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px", width:"20%"}}>
                                <label>Nº Documento</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="invoice"
                                        id="invoice"
                                        type="text"
                                        disabled
                                        maxLength={25}
                                        value={invoice}
                                        className="form-control "
                                        onChange={e => setInvoice(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"25%"}}>
                                <label>Valor Parcela</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="saleprice"
                                        id="saleprice"
                                        type="text"
                                        required
                                        disabled
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={amount}
                                        className="form-control text-right"
                                        onChange={setAmount}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"140"}}>
                                <label>Vencimento</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="dueDate"
                                        id="dueDate"
                                        type="text"
                                        required
                                        disabled
                                        value={duedate}
                                        className="form-control text-right"
                                        onChange={e => {setDueDate(e.target.value)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"100px"}}>
                                <label>Dias Atraso</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="daysOfDelay"
                                        id="daysOfDelay"
                                        type="text"
                                        disabled
                                        value={daysOfDelay}
                                        className="form-control text-right"
                                        onChange={e => {setDaysOfDelay(e.target.value)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"25%"}}>
                                <label>(%) Juros</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="fees"
                                        id="fees"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={fees}
                                        className="form-control text-right"
                                        onChange={setFees}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"25%"}}>
                                <label>($) Multa</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="fine"
                                        id="fine"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={fine}
                                        className="form-control text-right"
                                        onChange={setFine}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div style={{display:"flex"}}>
                            <div style={{width:"23%"}}>
                                <label>Valor Pago</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="amountPay"
                                        id="amountPay"
                                        type="text"
                                        disabled
                                        maxLength={100}
                                        value={amountPay}
                                        className="form-control text-right"
                                        onChange={e => {setAmountPay(e.target.value, statusInvoice, amount)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"23%"}}>
                                <label>Valor à Pagar</label>
                                <div className="input-group">
                                    <CurrencyInput  
                                        name="amountToPay"
                                        id="amountToPay"
                                        type="text"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe um valor!" }}
                                        value={amountToPay}
                                        className="form-control text-right"
                                        onChange={e => {handleStatus(e.target.value, statusInvoice, amount)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                            <div style={{marginLeft:"15px",width:"35%"}}>
                                <label>Data do Pagamento</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="payDay"
                                        id="payDay"
                                        type="date"
                                        required
                                        maxLength={100}
                                        errorMessage={{ required: "Por favor, informe uma data!" }}
                                        value={payDay}
                                        className="form-control text-right"
                                        onChange={e => {setPayDay(e.target.value)}}
                                        autoComplete="off"/>
                                </div>
                            </div>
                        </div>
                        <div>
                            <label>Observações</label>
                            <div className="input-group">
                                <TextInput  
                                    name="comments"
                                    id="comments"
                                    type="text"
                                    maxLength={100}
                                    multiline
                                    value={comments}
                                    className="form-control "
                                    onChange={e => setComments(e.target.value)}
                                     autoComplete="off"/>
                             </div>
                        </div>  
                        <div style={{marginTop:"15px",width:"100%"}} className={`${status === '' ? 'hidden' : ''}`}>
                            <div style={{padding: "5px", borderRadius: "5px", textAlign: "center", fontWeight:"bold", fontSize:"16px", border:"1px dashed #cacaca"}}>
                                {status}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-billstopay" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Baixar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}