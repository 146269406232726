import React, { useEffect, useState } from 'react';

import logo from './../../assets/logo-branco.png'
import logocolapse from './../../assets/logo-colapse.png'
import HeaderRun from './Header.run'
import Swal from 'sweetalert2'
import api from './../../services/api'
import ShowMessages from './../../components/Exceptions'
import {Dropdown} from 'react-bootstrap'
import ReactFlagsSelect from 'react-flags-select';
import { subHours } from 'date-fns';

import { IDCOMPANY, COMPANY, REGISTRATION, ReadDataStorage, WriteDataStorage, CONTRACT, EXPIRES_IN, CONTRACTSTATUS } from '../../services/auth'

export default function Header( {history, callbackCompany}) {
    const [company, setCompany]         = useState(0)
    const [dataCompany, setDataCompany] = useState([])
    const [alerts, setAlerts]           = useState([])
    const [totalAlerts, setTotalAlerts] = useState(0)
    const [language, setLanguage]       = useState('BR');
    const [expires_in, setExpires_in]   = useState(0)

    const elapsedTime = (_datetime) => {
        const agora = subHours(new Date(),3);
        const diferenca = agora.getTime() - _datetime.getTime();
        const _minutes = Math.floor(diferenca / 1000 / 60);
        const _hours = Math.floor(_minutes / 60);
        const _days = Math.floor(_hours / 24);
      
        let _message = ''

        if (_days > 0)
            _message = _message + _days + ' dia(s) '
        if (_hours % 24 > 0)
            _message = _message + _hours % 24 + ' hr(s) '
        if (_minutes % 60  > 0)
            _message = _message + _minutes % 60 + ' min(s) '

            console.log(_message)
        return _message;
    }

    const Translation = ( code ) => {
        if (code === 'US')
            ShowMessages({message : "Estamos trabalhando na tradução para o idioma selecionado!", status : 325}) 
        setLanguage('BR')
    }

    const setActiveCompany = async(value,company, contract = '', expires_in = '') => {
        callbackCompany(value)
        setCompany(value)
        WriteDataStorage(IDCOMPANY,value)
        WriteDataStorage(COMPANY,company)
        
        let _contract = dataCompany.filter((item) => {
            return(parseInt(item.value) === parseInt(value))
        })
        WriteDataStorage(CONTRACT,contract === '' ? _contract[0].contract : contract)
        let _expires_in = expires_in === '' ? _contract[0].expires_in : expires_in
        WriteDataStorage(EXPIRES_IN,_expires_in)
        setExpires_in(_expires_in)
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <a
          href="/#"
          ref={ref}
          className="custom-toogle"
          onClick={(e) => {
            e.preventDefault();
            onClick(e);
          }}
        >
          {children}
          <em className="open" style={{fontSize:"14px", marginLeft:"10px"}}></em>
        </a>
      ));

    useEffect(() => {
        getData()       
        HeaderRun();

        if (isNaN(parseInt(ReadDataStorage(CONTRACTSTATUS)))) {
            let _registration = ReadDataStorage(REGISTRATION)
            localStorage.clear()
            WriteDataStorage(REGISTRATION,_registration)
            window.location.href = '/login' 
        }

        if (parseInt(ReadDataStorage(CONTRACTSTATUS)) === 4)
            window.location.href = '/payment'
    },[])

    const toggleCollapsed = e => {
        e.preventDefault()
        document.querySelector('body').classList.toggle('aside-collapsed')
        resize()
    }

    const toggleAside = e => {
        e.preventDefault()
        document.querySelector('body').classList.toggle('aside-toggled')
    }
    
    const toogleChat = e => {
        e.preventDefault()
        document.querySelector('body').classList.toggle('offsidebar-open')
    }

    const Logout = async () => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente sair do sistema?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
          }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/logout')
                         .then(() => {  
                            let _registration = ReadDataStorage(REGISTRATION)     
                            localStorage.clear();
                            WriteDataStorage(REGISTRATION,_registration)
                            window.location.href = '/login'
                        }).catch(error => {
                            ShowMessages(error)
                        })  
            }
            
          })
    }

    const getData = async () => {
        await api.get('/getcompaniespermission')
                 .then((response) => { 
                    setDataCompany(response.data)
                    setActiveCompany(response.data[0].value,response.data[0].label, response.data[0].contract, response.data[0].expires_in)                  
                    getAlerts()
                    setInterval(() => {
                        getAlerts()
                    },300000)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
        
    }

    const getAlerts = async () => {
        await api.get('/alerts')
                 .then((response) => { 
                    console.log(response.data)
                    setAlerts(response.data || [])
                    setTotalAlerts(response.data.length)
                 }).catch(error => {
                    ShowMessages(error)
                 })  
        
    }

    const resize = () => {
        var evt = document.createEvent('UIEvents');
        evt.initUIEvent('resize', true, false, window, 0);
        window.dispatchEvent(evt);
    }

    const imgSize = "80%"

    return (
        <header className="topnavbar-wrapper">
            <nav className="navbar topnavbar">
                <div className="navbar-header only-desktop">
                    <a className="navbar-brand" href="#/">
                        <div className="brand-logo">
                            <img className="img-fluid" src={logo} alt={process.env.REACT_APP_NAME} style={{maxWidth:imgSize}}/>
                        </div>
                        <div className="brand-logo-collapsed">
                            <img className="img-fluid" src={logocolapse} alt={process.env.REACT_APP_NAME} style={{maxWidth:imgSize}}/>
                        </div>
                    </a>
                    
                </div>
                <div style={{flexGrow: 1}}>
                    <ul className="navbar-nav flex-row only-desktop">
                        <li className="nav-item">
                            <a href="/#" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ toggleCollapsed }>
                                <em className="fas fa-bars"></em>
                            </a>
                            <a href="/#" className="nav-link sidebar-toggle d-md-none" onClick={ toggleAside }>
                                <em className="fas fa-bars"></em>
                            </a>
                        </li>
                    </ul> 
                </div> 
                <ul className="navbar-nav mr-auto flex-row only-mobile" style={{minWidth:"250px"}}>
                    <li className="nav-item">
                        <a href="/#" className="nav-link d-none d-md-block d-lg-block d-xl-block" onClick={ toggleCollapsed }>
                            <em className="fas fa-bars"></em>
                        </a>
                        <a href="/#" className="nav-link sidebar-toggle d-md-none" onClick={ toggleAside }>
                            <em className="fas fa-bars"></em>
                        </a>
                    </li>

                    <li className="nav-item ">
                        <Dropdown title='Ajuda & Suporte' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"-1px"}}>
                        <Dropdown.Toggle as={CustomToggle} >
                            <em className="fa fa-question"></em>      
                            <span className="badge badge-warning">1</span> 
                        </Dropdown.Toggle>
                            <Dropdown.Menu style={{padding:"0px"}}>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'Meus Chamados', 'fa fa-question', '/helpdesk',{},1)}}>
                                        <div className="fa fa-question bg-primary icon-alert"></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>Meus Chamados</div>
                                            <div className="small mt-1">12h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{margin:"0px"}}/>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'Notícias', 'fa fa-rss', '/newsletter',{},1)}}>
                                        <div className="fa fa-rss bg-dark icon-alert" style={{ color:'#fff', paddingTop:'7px'}}></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>Notícias</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>    
                                </Dropdown.Item>
                                <Dropdown.Divider style={{margin:"0px"}}/>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'O que há de novo?', 'fa fa-newspaper', '/newsletter-system',{},1)}}>
                                        <div className="fa fa-newspaper bg-info icon-alert" style={{ color:'#fff', paddingLeft:'8px', fontSize:"26px"}}></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>O que há de novo?</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>   
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li> 
                    <li className="nav-item">
                        <Dropdown title='Alertas & Notificações' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"-1px"}}>
                            <Dropdown.Toggle as={CustomToggle} >
                                {totalAlerts > 0 ? 
                                    <div class="notification-box">
                                        <span class="notification-count">{totalAlerts}</span>                  
                                        <div class="notification-bell">
                                            <span class="bell-top"></span>
                                            <span class="bell-middle"></span>
                                            <span class="bell-bottom"></span>
                                            <span class="bell-rad"></span>
                                        </div>
                                    </div>
                                :
                                    <em className="fa fa-bell"></em> 
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{padding:"0px", width:'295px'}}>
                                {totalAlerts > 0 ? 
                                    <div className="bg-warning text-center text-white font-weight-bold p-3">Você tem {totalAlerts} novos alertas!</div> 
                                :
                                    <div className="bg-success text-center text-white font-weight-bold p-3">Você não possui novos alertas!</div> 
                                }
                                <div style={{maxHeight: "265px", overflow: "hidden auto", height:"auto"}}>
                                    {alerts.map((item, key) => {
                                        return (<div key={key}>
                                                    <Dropdown.Divider style={{margin:"0px"}}/>
                                                    <Dropdown.Item href="#" >
                                                        <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                                     window.addTab(e, 'Alerta | ' + item.title, 'fa fa-bell', '/alert',{ id : item.idalert},1)}}>
                                                            {item.idtype === 1 ?
                                                                <div className="bg-success icon-alert" style={{ color:'#fff', paddingLeft:'9px', paddingTop:"0px"}}>
                                                                    <i className='fa fa-thumbs-up'></i>
                                                                </div>
                                                             : item.idtype === 0 ?
                                                                <div className="bg-warning icon-alert" style={{ color:'#fff', paddingLeft:'18px', paddingTop:"1px"}}>
                                                                    <i className='fa fa-exclamation'></i>
                                                                </div>
                                                             :
                                                                <div className="bg-danger icon-alert" style={{ color:'#fff', paddingLeft:'7px', paddingTop:"1px"}}>
                                                                    <i className='fa fa-ban'></i>
                                                                </div>
                                                            }
                                                            <div className="line-height-condenced media-body" style={{width:'200px', marginLeft:"15px"}}>
                                                                <div style={{fontWeight:'bold'}}>{item.title}</div>
                                                                <div className="small mt-1">{elapsedTime(new Date(item.create_at))}</div>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                </div>)
                                    })}   
                                </div>
                                <div className="text-center font-weight-bold p-2 border-top" style={{fontSize:"14px"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                                            window.addTab(e, 'Todos os Alertas', 'fa fa-bell', '/alert-all',{},1)}}><i className="fa fa-bell"/> Visualizar todos alertas</div>
                           </Dropdown.Menu>
                                
                                
                        </Dropdown>
                    </li>   
                    <li id="select-company" className="nav-item  li-click" style={{width:"200px", marginTop:"12px"}}>                                        
                        <select 
                            name="company" 
                            id="company" 
                            placeholder="Selecione" 
                            value={company} 
                            className="form-select"
                            onChange={e => setActiveCompany(e.target.value,e.target.options[e.target.selectedIndex].text)}>
                            {(dataCompany !== null) ?     
                                 dataCompany.map((data, id) => {
                                     return (<option key={id} value={data.value}>{data.label}</option>)
                                }) : ""} 
                        </select>
                    </li>
                    {process.env.REACT_APP_ENABLED_CHAT === true ? 
                        <li className="nav-item  li-click" style={{width:100 + "%"}}>
                            <a title="Chat" href="#" className="nav-link" style={{textAlign:"right"}} onClick={toogleChat}>
                                <em className="fa fa-comments"></em>      
                                <span className="badge badge-danger">11</span> 
                            </a>
                        </li>
                    : ""}
                    <li className="nav-item  li-click" style={{width:"40px"}}>
                        <a title="Sair do Sistema" onClick={e => Logout()} className="nav-link" style={{textAlign:"right"}}>
                            <em className="fa fa-times-circle"></em>     
                        </a>
                    </li>        
                          
                </ul>   
                <ul className="navbar-nav flex-row only-desktop" style={{minWidth:"300px"}}>
                    <li className="nav-item" style={{display:"flex", alignItems:"center",margin:"5px"}}>
                        <div className={`${expires_in <= 2 ? 'bg-danger' : 'bg-primary'}`} style={{width:"150px", padding:"5px", borderRadius:"5px", display:"flex", justifyContent:"center"}}> 
                            <span>Expira em {expires_in} Dia(s)</span>
                        </div>    
                    </li>

                    <li className="nav-item d-none d-md-block li-click ">
                        <Dropdown title='Ajuda & Suporte' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"-1px"}}>
                        <Dropdown.Toggle as={CustomToggle} >
                            <em className="fa fa-question"></em>      
                            {/* <span className="badge badge-warning">1</span>  */}
                        </Dropdown.Toggle>
                            <Dropdown.Menu style={{padding:"0px"}}>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'Meus Chamados', 'fa fa-question', '/helpdesk',{},1)}}>
                                        <div className="fa fa-question bg-primary icon-alert"></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>Meus Chamados</div>
                                            <div className="small mt-1">12h atrás</div>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <Dropdown.Divider style={{margin:"0px"}}/>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'Notícias', 'fa fa-rss', '/newsletter',{},1)}}>
                                        <div className="fa fa-rss bg-dark icon-alert" style={{ color:'#fff', paddingTop:'7px'}}></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>Notícias</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>    
                                </Dropdown.Item>
                                <Dropdown.Divider style={{margin:"0px"}}/>
                                <Dropdown.Item href="#" >
                                    <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                 window.addTab(e, 'O que há de novo?', 'fa fa-newspaper', '/newsletter-system',{},1)}}>
                                        <div className="fa fa-newspaper bg-info icon-alert" style={{ color:'#fff', paddingLeft:'8px', fontSize:"26px"}}></div>
                                        <div className="line-height-condenced ml-3 media-body" style={{width:'200px'}}>
                                            <div style={{fontWeight:'bold'}}>O que há de novo?</div>
                                            <div className="small mt-1">2h atrás</div>
                                        </div>
                                    </div>   
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </li>  
                    <li className="nav-item d-none d-md-block li-click ">
                        <Dropdown title='Alertas & Notificações' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"-1px"}}>
                            <Dropdown.Toggle as={CustomToggle} >
                  
                                {totalAlerts > 0 ? 
                                    <div class="notification-box">
                                        <span class="notification-count">{totalAlerts}</span>                  
                                        <div class="notification-bell">
                                            <span class="bell-top"></span>
                                            <span class="bell-middle"></span>
                                            <span class="bell-bottom"></span>
                                            <span class="bell-rad"></span>
                                        </div>
                                    </div>
                                :
                                    <em className="fa fa-bell"></em> 
                                }
                            </Dropdown.Toggle>
                            <Dropdown.Menu style={{padding:"0px", width:'295px'}}>
                                {totalAlerts > 0 ? 
                                    <div className="bg-warning text-center text-white font-weight-bold p-3">Você tem {totalAlerts} novos alertas!</div> 
                                :
                                    <div className="bg-success text-center text-white font-weight-bold p-3">Você não possui novos alertas!</div> 
                                }
                                <div style={{maxHeight: "265px", overflow: "hidden auto", height:"auto"}}>
                                    {alerts.map((item, key) => {
                                        return (<div key={key}>
                                                    <Dropdown.Divider style={{margin:"0px"}}/>
                                                    <Dropdown.Item href="#" >
                                                        <div style={{display:"flex"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                                     window.addTab(e, 'Alerta | ' + item.title, 'fa fa-bell', '/alert',{ id : item.idalert},1)}}>
                                                            {item.idtype === 1 ?
                                                                <div className="bg-success icon-alert" style={{ color:'#fff', paddingLeft:'9px', paddingTop:"0px"}}>
                                                                    <i className='fa fa-thumbs-up'></i>
                                                                </div>
                                                             : item.idtype === 0 ?
                                                                <div className="bg-warning icon-alert" style={{ color:'#fff', paddingLeft:'18px', paddingTop:"1px"}}>
                                                                    <i className='fa fa-exclamation'></i>
                                                                </div>
                                                             :
                                                                <div className="bg-danger icon-alert" style={{ color:'#fff', paddingLeft:'7px', paddingTop:"1px"}}>
                                                                    <i className='fa fa-ban'></i>
                                                                </div>
                                                            }
                                                            <div className="line-height-condenced media-body" style={{width:'200px', marginLeft:"15px"}}>
                                                                <div style={{fontWeight:'bold'}}>{item.title}</div>
                                                                <div className="small mt-1">{elapsedTime(new Date(item.create_at))}</div>
                                                            </div>
                                                        </div>
                                                    </Dropdown.Item>
                                                </div>)
                                    })}   
                                </div>
                                <div className="text-center font-weight-bold p-2 border-top" style={{fontSize:"14px"}} onClick={e => {document.querySelector('.dropdown-menu.show').classList.toggle('show');
                                                                                                            window.addTab(e, 'Todos os Alertas', 'fa fa-bell', '/alert-all',{},1)}}><i className="fa fa-bell"/> Visualizar todos alertas</div>
                           </Dropdown.Menu>
                                
                                
                        </Dropdown>
                    </li>  
                    <li className="nav-item li-click li-company-title" title="Selecione a empresa">
                        <select 
                            name="company" 
                            id="company" 
                            placeholder="Selecione" 
                            value={company} 
                            className="form-select"
                            onChange={e => setActiveCompany(e.target.value, e.target.options[e.target.selectedIndex].text)}>
                            {(dataCompany !== null) ?    
                                 dataCompany.map((data, id) => {
                                     return (<option key={id} value={data.value}>{data.label}</option>)
                                 }) : ""} 
                        </select> 
                    </li>  
                    <li className="nav-item li-click" style={{marginLeft:"15px", display:"flex", alignItems:"center"}} title="Selecione o idioma do sistema">
                        <ReactFlagsSelect
                            className="containt-combo-flag "
                            selectButtonClassName='combo-flag'
                            showSelectedLabel={false}
                            showOptionLabel={false}
                            selected={language}
                            onSelect={code => Translation(code)}
                            countries={["BR","US"]}
                                                    />
                    </li>
                    {process.env.REACT_APP_ENABLED_CHAT === true ? 
                        <li className="nav-item d-none d-md-block li-click" style={{width:"auto"}}>
                            <a title="Chat" href='#' className="nav-link" style={{textAlign:"right", display:"flex", marginTop:"3px"}} onClick={toogleChat}>
                                <em className="fa fa-comments"></em>      
                                <span className="badge badge-danger">1</span> 
                            </a>
                        </li>  
                    : ""}    
                    <li className="nav-item d-none d-md-block li-click ">
                        <a title="Sair do Sistema" onClick={e => Logout()} className="nav-link">
                            <em className="fa fa-times-circle"></em>     
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
        );
    
}
