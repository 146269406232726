import React, { useState, useEffect, useCallback } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalAttendanceFlux from '../modals/modal-attendance-flux';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import { checkPermission } from '../../components/security'
import dagre from 'dagre';
import ReactFlow, {Background, isNode } from 'react-flow-renderer';
import Swal from 'sweetalert2'

export default function AttendanceFlux() {
    const [loading, setLoading]                        = useState(false)
    const [showModal, setShowModal]                    = useState(false)
    const [isOpenFilter, setIsOpenFilter]              = useState(false)
    const [reload, setReload]                          = useState(false)
    const [print, setPrint]                            = useState(false)
    const [excel, setExcel]                            = useState(false)
    const [showButtons, setShowButtons]                = useState(false)
    const [editState, setEditState]                    = useState(false)
    const [gridParams, setGridParams]                  = useState({})
    const [elementsData, setElementsData]              = useState([])
    const [data, setData]                              = useState({})
    const [filters, setFilters]                        = useState([])
    const [teste, setTeste]                            = useState(false)
    const [editPermission, setEditPermisson]           = useState(false)
     
    const RemoveAttendanceFlux = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o fluxo de atendimento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/attendance-flux/' + id)
                         .then(response => {
                            ShowMessages(response.data)
                            setReload(true)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        })     
    }

    const EditAttendanceFlux  = async(id) => {
        await api.get('/attendance-flux/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    const loadFlux = async() => {
        const position = { x: 0, y: 0 };
        await api.get('attendance-flux/1/100/')
                 .then(response => {
                    let _cat = Math.floor((Math.random() * 100000) + 1);
                    let _data = response.data.data
                    let _aux  = []
                    _data.map((_row) => {
                        let element = {id   : _row.idattendanceflux + _cat,
                                       data : { label : _row.name},
                                       position,
                                       type  : 'input',
                                       style : {background : _row.style.background,
                                                color      : _row.style.color,
                                                fontSize   : _row.style.fontSize,
                                                fontFamily : _row.style.fontFamily}}
                   
                   _aux.push(element)
               })
               _data.map((_row) => {                            
                   let element = {id       : 'e' + _row.idattendanceflux + _cat + '-' + _row.idattendancefluxnext + _cat,
                                  source   : _row.idattendanceflux + _cat,
                                  target   : _row.idattendancefluxnext + _cat,
                                  animated : true}
                   
                   _aux.push(element)

                   if (_row.idattendancefluxprior > 0)
                       element = {id       : 'e' + _row.idattendanceflux + _cat + '-' + _row.idattendancefluxprior + _cat,
                                  source   : _row.idattendanceflux + _cat,
                                  target   : _row.idattendancefluxprior + _cat,
                                  animated : true}
                   
                   _aux.push(element)
               }) 
               setElementsData(getLayoutedElements(_aux))                    
        })
        .catch( error => { 
            ShowMessages(error)
        })
    }

    const getData = async() => {
        let _delCheck  = !checkPermission(4,12)
        let _editCheck = !checkPermission(8,12)

        setEditPermisson(_editCheck)
        setGridParams({idattendanceflux  : {text          : "#ID", mask : { name  : 'leftSlice',
                                                                            value : '00000'},
                                            primaryKey    : true                          },
                       name              : {text          : "Nome",
                                            searchDefault : true},
                       name_prior        : {text          : 'Fluxo Anterior'},
                       name_next         : {text          : 'Fluxo Seguinte'},
                       Options           : {text          : "Opções",
                                            className     : "text-center",
                                            order         : false,
                                            filter        : false,
                                            item          : [{type  : "Custom",
                                                              props : {tag            : "button",
                                                                       title          : "Exclusão de fluxo de atendimento",
                                                                       className      : 'btn btn-sm btn-danger',
                                                                       icon           : "fa fa-trash",
                                                                       disabled       : _delCheck,
                                                                       message        : "Deseja realmente excluir o fluxo de atendimento?",
                                                                       indexcallback  : 0}},
                                                             {type  : "Custom",
                                                              props : {tag            : "button",
                                                                       title          : "Editar fluxo de atendimento",
                                                                       disabled       : _editCheck,
                                                                       className      : 'btn btn-sm btn-warning',
                                                                       icon           : "fa fa-edit",
                                                                       indexcallback  : 1}}]
                                           }
        })
    }

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const getLayoutedElements = (elements, direction = 'TB') => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });
        elements.forEach((el) => {
            if (isNode(el)) {
                dagreGraph.setNode(el.id, { width: 150, height: 50 });
            } else {
                dagreGraph.setEdge(el.source, el.target);
            }
        });
        dagre.layout(dagreGraph);
   
        return elements.map((el) => {
            if (isNode(el)) {
                const nodeWithPosition = dagreGraph.node(el.id);
                el.targetPosition = isHorizontal ? 'left' : 'top';
                el.sourcePosition = isHorizontal ? 'right' : 'bottom';
                el.position = {
                    x: nodeWithPosition.x + Math.random() / 1000,
                    y: nodeWithPosition.y,
                };
            }
            return el;
        });
    };
 
    // const onConnect = (params) =>
    //       setElementsData((els) =>
    //           addEdge({ ...params, type: 'smoothstep', animated: true }, els)
    //       );
    // const onElementsRemove = (elementsToRemove) =>
    //     setElementsData((els) => removeElements(elementsToRemove, els));
    // const onLayout = useCallback(
    //     (direction) => {
    //         const layoutedElements = getLayoutedElements(elementsData, direction);
    //         setElementsData(layoutedElements);
    //     },
    //     [elementsData]
    // );

    const handleCloseModal = () => { setShowModal(false) }
    const handleFilter = () => { setIsOpenFilter(!isOpenFilter) }


    useEffect(() => {
        loadFlux()
    }, [reload])

    useEffect(() => {
        getData()
        setStatistic('maintenance\\attendance-flux')
    }, [])

    return (
        <ContentWrapper>
            <Loading loading={loading} />
            <ModalAttendanceFlux showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2, 5)} title="Insere um novo registro" onClick={e => {
                                setEditState(false)
                                setShowModal(true)
                            }}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{ marginLeft: "3px" }} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ?
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{ marginLeft: "3px" }}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16, 5)} title="Exportar para excel" onClick={e => setExcel(true)} style={{ marginLeft: "3px" }}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{ marginLeft: "3px" }} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <div style={{display:"flex",width:"100%"}}>
                    <div style={{width:"50%", marginRight:"15px", display:"flex"}}>
                        <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter} />
                        <Grid url               = '/attendance-flux'
                            headers             = {gridParams}
                            loading             = {setLoading}
                            reload              = {reload}
                            print               = {print}
                            excel               = {excel}
                            filters             = {filters}
                            callbackShowButtons = {setShowButtons}
                            callbackPrint       = {setPrint}
                            callbackReload      = {setReload}
                            callbackExcel       = {setExcel}
                            filename            = "Fluxo de Atendimento"
                            callbackCellClick   = {editPermission ? undefined : EditAttendanceFlux}
                            callbackButtons     = {[RemoveAttendanceFlux,
                                                    EditAttendanceFlux]}
                        />
                    </div>
                    <div style={{width:"50%", borderLeft: "1px dashed #cacaca"}}>
                        <ReactFlow elements={elementsData} style={{ width: 100 + "%", height: "76vh", overflow:"auto",marginLeft:'15px' }}>
                            <Background color="#cacaca" gap={16} />
                        </ReactFlow>
                    </div>
                </div>
            </div>
        </ContentWrapper>
    )
}