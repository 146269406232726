import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import Draggable from 'react-draggable';

import { Modal, ModalDialog, Alert } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'
import TooltipItem from '../../components/tooltip';
import MaskWithValidation from './../../components/maskInput'

import api from '../../services/api';
import { IDCOMPANY, ReadDataStorage } from '../../services/auth';
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalHealthInsurance({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                                = useState(false)
    const [tabActive, setTabActive]                            = useState(0)

    const [healthinsurance,setHealthinsurance]                 = useState('')
    const [corporatename,setCorporatename]                     = useState('')
    const [Cnpj,setCnpj]                                       = useState('')
    const [idhealthinsurancetype,setIdhealthinsurancetype]     = useState('')
    const [initialterm,setInitialterm]                         = useState(null)
    const [finalterm,setFinalterm]                             = useState(null)
    const [dueday,setDueday]                                   = useState('')
    const [registerans,setRegisterans]                         = useState('')
    const [registerplan,setRegisterPlan]                       = useState('')
    const [register,setRegister]                               = useState('')
    const [urlGuia, setUrlGuia]                                = useState('')
    const [returndays, setReturnDays]                          = useState(0)
    const [specialists, setSpecialists]                        = useState([])

    const [dataHealthInsuranceType,setDataHealthInsuranceType] = useState([])
    const [dataSpecialties, setDataSpecialties]                = useState([])

    const [specialist, setSpecialist]                          = useState(0)
    const [messageSpecialist, setMessageSpecialist]            = useState('')

    const addSpecialist = () => {
        setMessageSpecialist('')

        if (specialist !== 0) {
            if (!specialists.find(el => (el.id === specialist))) {
                setSpecialists(specialists => specialists.concat({id   : specialist, 
                                                                        name : dataSpecialties.find(el => el.value === specialist)['label']})) 
                setSpecialist(0)                                              
            } else 
                setMessageSpecialist('O especialista informado já está cadastrado!')    
        } else {
            setMessageSpecialist('Por favor, informe um especialista.')
            return
        }   
    }

    const removeSpecialist = (id, value) => {
        const _remove = specialists.filter((t, i) => {
            if ((t.id !== id) && (t.name !== value))
                return true;
        
            return false;
        });
        setSpecialists(_remove)
    }

    const editSpecialist = (id, value) => {
        let _data = specialists.find(el => ((el.id === id) && (el.name === value)))
        setSpecialist(_data.id)                                              
        removeSpecialist(id,value)
    }

    const setData = (obj,clear) => {
        setTabActive(0)
        setHealthinsurance(clear ? '' : obj.healthinsurance)
        setCorporatename(clear ? '' : obj.corporatename)
        setCnpj(clear ? '' : obj.cnpj)
        setIdhealthinsurancetype(clear ? '' : obj.idhealthinsurancetype)
        setInitialterm(clear ? '' : obj.initialterm ? obj.initialterm.substr(0,10) : '')
        setFinalterm(clear ? '' : obj.finalterm ? obj.finalterm.substr(0,10) : '')
        setDueday(clear ? '' : obj.dueday)
        setRegisterans(clear ? '' : obj.registerans)
        setRegister(clear ? '' : obj.register)
        setRegisterPlan(clear ? '' : obj.registerplan)
        setUrlGuia(clear ? '' : obj.urlguia)
        setReturnDays(clear ? '' : obj.returndays)
        setSpecialists(clear ? [] : obj.specialists || [])                 
                         
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-health-insurance'),document.querySelector('#submit-health-insurance').children[0].classList,document.querySelector('#submit-health-insurance').lastChild.nodeValue)
        event.preventDefault()

        try {
            let idcompany = ReadDataStorage(IDCOMPANY)

            let _data = {healthinsurance,
                         corporatename,
                         Cnpj,
                         idhealthinsurancetype,
                         initialterm,
                         finalterm,
                         dueday,
                         registerans,
                         register,
                         registerplan,
                         idcompany,
                         urlGuia,
                         returndays,
                         specialists }

            if (editState)
                await api.put('/health-insurance/' + data.idhealthinsurance, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-health-insurance'))
                            }})
                            .catch(response => {
                                loadingButton(false,document.querySelector('#submit-health-insurance')) 
                                ShowMessages(response);
                             })
            else
                await api.post('/health-insurance', _data)
                         .then(response => {
                            ShowMessages(response) 
                             if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-health-insurance'))        
                             } else 
                                loadingButton(false,document.querySelector('#submit-health-insurance')) 
                        })
                        .catch(response => {
                            loadingButton(false,document.querySelector('#submit-health-insurance')) 
                            ShowMessages(response);
                         })
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-health-insurance'))
            ShowMessages(response)
        }
    }

    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getHealthInsuranceData')
                     .then(response => {
                        if (response.ok) {
                            setDataHealthInsuranceType(response.data.healthinsurance || [])
                            setDataSpecialties(response.data.specialists || [])
                            setLoading(false) 
                        }
                     }).catch(error => {
                        setLoading(false) 
                        ShowMessages(error) 
                     }) 
                     
        } catch (response) {
            ShowMessages(response)    
        }
    }
    
    useEffect(() => {
        setStatistic('modals\\modal-health-insurance')
    },[])

    useEffect(() => {
        if (showModal)
            getData()

        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    const checkData = (event, formData, errorInputs) => {
        if ((formData.corporatename         === "") ||
            (formData.healthinsurance       === "") ||
            (formData.Cnpj                  === "") ||
            (formData.idhealthinsurancetype === "") || 
            (formData.returnDays            === "") ||
            (formData.initialterm           === "") ||
            (formData.finalterm             === "") ||
            (formData.dueday                === "") ||
            (formData.registerans           === "") ||
            (formData.registerplan          === "") || 
            (formData.register              === "") ||
            (formData.urlguia               === "")) {
            document.querySelector('#principal-data').style.color = "#dc3545"
            document.querySelector('#principal-data').parentElement.click()
        } else 
            document.querySelector('#principal-data').style.color = "#656565"


    }

    return (
      
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog} dialogClassName="width550 height550">
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" onErrorSubmit={checkData}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-handshake"></i> Propriedades do Convênio
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth390px"/>
                    <div className={`${loading ? "hidden" : ""}`}>  
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    <span id="principal-data">Dados Principais</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    <span id="specialist-data">Especialistas</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId={0}>
                                <div>
                                    <label>Razão Social</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="corporatename"
                                            id="corporatename"
                                            type="text"
                                            required
                                            maxLength={150}
                                            errorMessage={{ required: "Por favor, informe uma razão social!" }}
                                            value={corporatename}
                                            autoFocus={true}
                                            className="form-control "
                                            onChange={e => setCorporatename(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div>
                                    <label>Convênio</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="healthinsurance"
                                            id="healthinsurance"
                                            type="text"
                                            required
                                            maxLength={150}
                                            errorMessage={{ required: "Por favor, informe um convênio!" }}
                                            value={healthinsurance}
                                            className="form-control "
                                            onChange={e => setHealthinsurance(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{width:35 + "%"}}>
                                        <label>CNPJ</label>
                                        <div className="input-group">
                                            <MaskWithValidation  
                                                name="Cnpj"
                                                id="Cnpj"
                                                type="text"
                                                required
                                                errorMessage={{ required: "Por favor, informe o CNPJ!" }}
                                                value={Cnpj}
                                                className="form-control "
                                                onChange={e => setCnpj(e.target.value)}
                                                mask={[/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'.',/[0-9]/,/[0-9]/,/[0-9]/,'/',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/]}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:45+ "%", marginLeft:"15px"}}>
                                        <label>Tipo de Convênio</label>
                                        <div className="input-group">
                                            <SelectGroup 
                                                name="idhealthinsurancetype" 
                                                id="idhealthinsurancetype" 
                                                required 
                                                errorMessage={{ required: "Por favor, informe um tipo de convênio!" }}
                                                placeholder="Selecione" 
                                                className="form-select"
                                                value={idhealthinsurancetype} 
                                                onChange={e => setIdhealthinsurancetype(parseInt(e.target.value))}>
                                                    <option  value=''>Selecione</option>
                                                    {dataHealthInsuranceType.map((data, id) => {
                                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                                    })} 
                                            </SelectGroup>       
                                        </div>
                                    </div>
                                    <div style={{width:20+ "%", marginLeft:"15px"}}>
                                        <label>Dias p/ Retorno</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="returnDays"
                                                id="returnDays"
                                                type="text"
                                                required
                                                maxLength={150}
                                                errorMessage={{ required: "Por favor, informe a quantidade de dias de retorno!" }}
                                                value={returndays}
                                                className="form-control "
                                                onChange={e => setReturnDays(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>           
                                <div style={{display:"flex"}}>
                                    <div style={{width:"calc(37% - 15px)",maxWidth:"calc(37% - 15px)", minWidth:"calc(37% - 15px)"}}>
                                        <label>Inicio do Contrato</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="initialterm"
                                                id="initialterm"
                                                type="date"
                                                required
                                                errorMessage={{ required: "Por favor, informe a data do inicio do contrato!" }}
                                                value={initialterm}
                                                className="form-control "
                                                onChange={e => setInitialterm(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:"calc(37% - 15px)", marginLeft:"15px",maxWidth:"calc(37% - 15px)", minWidth:"calc(37% - 15px)"}}>
                                        <label>Fim do Contrato</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="finalterm"
                                                id="finalterm"
                                                type="date"
                                                required
                                                errorMessage={{ required: "Por favor, informe a data do fim do contrato!" }}
                                                value={finalterm}
                                                className="form-control "
                                                onChange={e => setFinalterm(e.target.value)}
                                                autoComplete="off"/>      
                                        </div>
                                    </div>
                                    <div style={{width:26+ "%", marginLeft:"15px",maxWidth:26 + "%",minWidth:26 + "%"}}>
                                        <label>Dia do Vencimento</label>
                                        <div className="input-group">
                                            <TextInput 
                                                name="dueday" 
                                                id="dueday" 
                                                required 
                                                errorMessage={{ required: "Por favor, informe o dia do vencimento!" }}
                                                placeholder="Selecione" 
                                                className="form-control "
                                                autoComplete="off"
                                                value={dueday} 
                                                onChange={e => setDueday(parseInt(e.target.value))}/>       
                                        </div>
                                    </div>
                                </div>
                                <div style={{display:"flex"}}>
                                    <div style={{width:33+ "%"}}>
                                        <label>N° Registro ANS</label>
                                        <div className="input-group">
                                            <TextInput 
                                                name="registerans" 
                                                id="registerans" 
                                                type="text"
                                                required 
                                                errorMessage={{ required: "Por favor, informe o número do registro da ANS!" }}
                                                className="form-control "
                                                value={registerans} 
                                                autoComplete="off"
                                                maxLength={15}
                                                onChange={e => setRegisterans(parseInt(e.target.value))}/>  
                                        </div>
                                    </div>
                                    <div style={{width:33 + "%", marginLeft:"15px"}}>
                                        <label>N° do Registro do Plano</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="registerplan"
                                                id="registerplan"
                                                type="text"
                                                required
                                                errorMessage={{ required: "Por favor, informe o número do registro do plano!" }}
                                                value={registerplan}
                                                className="form-control "
                                                maxLength={15}
                                                onChange={e => setRegisterPlan(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:33 + "%", marginLeft:"15px"}}>
                                        <label>N° do Registro Usuário</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="register"
                                                id="register"
                                                type="text"
                                                required
                                                errorMessage={{ required: "Por favor, informe o número do registro!" }}
                                                value={register}
                                                className="form-control "
                                                maxLength={15}
                                                onChange={e => setRegister(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:100 + "%"}}>
                                    <label>URL de Emissão da Guia</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="urlguia"
                                            id="urlguia"
                                            type="text"
                                            required
                                            errorMessage={{ required: "Por favor, informe a url de emissão da guia!" }}
                                            value={urlGuia}
                                            className="form-control "
                                            maxLength={1500}
                                            onChange={e => setUrlGuia(e.target.value.toLowerCase())}
                                            autoComplete="off"/>
                                    </div>
                                </div>
                            </TabPane>
                            <TabPane tabId={1}>
                                <div style={{display:"flex"}}>
                                    <div style={{width:"432px", minWidth:"432px", maxWidth:"432px", marginBottom:"10px"}}>
                                        <label>Especialista</label>
                                        <div className="dv-typecontact">
                                            <select 
                                                name="specialist" 
                                                id="specialist" 
                                                placeholder="Selecione" 
                                                className="form-select"
                                                value={specialist} 
                                                onChange={e => setSpecialist(parseInt(e.target.value))}>
                                                    <option value="">Selecione</option>
                                                    {dataSpecialties.map((data, id) => {
                                                        return (<option key={id} value={data.value}>{data.label}</option>)
                                                })} 
                                            </select> 
                                        </div>
                                    </div>
                                    <div style={{display:"flex",alignItems:"center", justifyContent:"center"}}>
                                        <TooltipItem  content="Click aqui para inserir um novo especialista" className="background-info">
                                            <button type="button" className="btn btn-sm btn-success" style={{marginLeft: "15px", marginTop: "8px"}} onClick={e => addSpecialist()}><em className="fa fa-arrow-down"></em></button>
                                        </TooltipItem>                                   
                                    </div>
                                </div>      
                                     
                                <div style={{ overflow:"auto", height:"187px"}}>
                                    <Alert variant="danger" className={`${messageSpecialist === "" ? "hidden" : ""}`}>
                                        {messageSpecialist}
                                    </Alert>
                                    <table className="table table-striped w-100">
                                        <thead className="thead-theme">
                                            <tr className="head-th">
                                                <td className="head-th" style={{width:"175px", minWidth:"175px", maxWidth:"175px"}}>Especialista</td>
                                                <td style={{textAlign:"center", width:"100px"}}>
                                                    ...
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {specialists.map((item, key) => {
                                                return (
                                                    <tr className="head-th" key={key}>
                                                        <td>{item.name}</td>
                                                        <td style={{textAlign:"center"}}>
                                                            <button type="button" className="btn btn-danger btn-sm" onClick={e => removeSpecialist(item.id, item.name)}><em className="fa fa-trash" ></em></button>
                                                            <button type="button" className="btn btn-warning  btn-sm" onClick={e => editSpecialist(item.id, item.name)} style={{marginLeft:"5px"}}><em className="fa fa-edit"></em></button>
                                                        </td>
                                                    </tr>
                                                )
                                            })}     
                                        </tbody>
                                    </table>
                                    <div className={`box-sem-dados bg-warning ${specialists.length > 0 ? "hidden" : ""}`}>Ainda não foi incluido nenhum especialista.</div>
                                </div>
                            </TabPane>
                        </TabContent>
                    </div>    
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-health-insurance" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
       
    )
}