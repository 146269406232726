import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';
import { SketchPicker } from 'react-color';

import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import  loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalAttendanceFlux({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                       = useState(false)

    const [name, setName]                             = useState('')
    const [idAttendanceNext, setIdAttendanceNext]     = useState(0)
    const [idAttendancePrior, setIdAttendancePrior]   = useState(0)
    const [color, setColor]                           = useState('')
    const [cancel, setCancel]                         = useState(false)
    const [start, setStart]                           = useState(false)

    const [attendanceFluxData, setAttendanceFluxData] = useState([])

    const setData = (obj,clear) => {
        setName(clear ? '' : obj.name) 
        setIdAttendanceNext(clear ? '' : obj.idattendancefluxnext) 
        setIdAttendancePrior(clear ? '' : obj.idattendancefluxprior) 
        setColor(clear ? '' : obj.style.background === null ? '#fff' : obj.style.background)  
        setCancel(clear ? false : obj.cancel)
        setStart(clear ? false : obj.start)
    }

    const handleSubmit = async(event) => {
        event.preventDefault()
        loadingButton(true,document.querySelector('#submit-attendance'),document.querySelector('#submit-attendance').children[0].classList,document.querySelector('#submit-attendance').lastChild.nodeValue)        
        try {
             let _data = {name,
                          idAttendanceNext,
                          idAttendancePrior,
                          cancel,
                          start,
                          color}

            if (editState)
                await api.put('/attendance-flux/' + data.idattendanceflux, _data)
                         .then(response => {
                            ShowMessages(response) 
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-attendance'))
                            }else 
                                loadingButton(false,document.querySelector('#submit-attendance'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-attendance'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/attendance-flux', _data)
                         .then(response => {
                             ShowMessages(response) 
                             if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)     
                                } else 
                                    loadingButton(false,document.querySelector('#submit-attendance'))         
                             } else 
                                loadingButton(false,document.querySelector('#submit-attendance'))
                        })
                         .catch( error => {
                            loadingButton(false,document.querySelector('#submit-attendance'))
                            ShowMessages(error) 
                         })
            
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-attendance'))
            ShowMessages(response)
        }
    }
    
    const getData = async() => {
        setLoading(true)
        await api.get('/getAttendanceFluxData')
                 .then(response => {
                    if (response.ok) 
                        setAttendanceFluxData(response.data) 
                    setLoading(false)     
                 })
                 .catch(error =>  {
                    ShowMessages(error) 
                 })
    }

    useEffect(() => {
        getData()
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    

    useEffect(() => {
        setStatistic('modals\\modal-attendance-flux')
    } ,[])

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog} dialogClassName="width500">
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true}  className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-sign-in-alt"></i> Propriedades de Fluxo de Atendimento
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth330px"/>
                    <div className={`${loading ? "hidden" : ""}`} style={{flexDirection:"row", display:"flex"}}>       
                        <div style={{width:50 + '%'}}>
                            <div>
                                <label>Fluxo Atual</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="flux"
                                        id="flux"
                                        type="text"
                                        autoFocus={true}
                                        maxLength={50}
                                        required
                                        errorMessage={{ required: "Por favor, informe um fluxo!" }}
                                        value={name}
                                        className="form-control "
                                        onChange={e => setName(e.target.value)}
                                        autoComplete="off"/>
                                </div>
                            </div>                                    
                            <div>
                                <label>Fluxo Anterior</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="priorflux" 
                                        id="priorflux" 
                                        placeholder="Selecione" 
                                        className="form-select"
                                        value={idAttendancePrior} 
                                        onChange={e => setIdAttendancePrior(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {attendanceFluxData.map((data, id) => {
                                                return (<option key={id} value={data.value}>{data.label}</option>)
                                            })} 
                                    </SelectGroup>
                                </div>
                            </div>                                           
                            <div>
                                <label>Próximo Fluxo</label>
                                <div className="input-group">
                                    <SelectGroup 
                                        name="nextflux" 
                                        id="nextflux" 
                                        placeholder="Selecione" 
                                        className="form-select"
                                        value={idAttendanceNext} 
                                        onChange={e => setIdAttendanceNext(parseInt(e.target.value))}>
                                            <option value="">Selecione</option>
                                            {attendanceFluxData.map((data, id) => {
                                                return (<option key={id} value={data.value}>{data.label}</option>)
                                            })} 
                                    </SelectGroup>
                                </div>
                            </div>
                            <div style={{display: "flex", marginTop: "10px", alignItems:"center"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" defaultChecked={start} value={start} onClick={e => setStart(!start)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <label style={{marginLeft: "15px"}}>Processo de Inicial?</label>
                            </div> 
                            <div style={{display: "flex", marginTop: "10px", alignItems:"center"}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" defaultChecked={cancel} value={cancel} onClick={e => setCancel(!cancel)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <label style={{marginLeft: "15px"}}>Processo de Cancelamento?</label>
                            </div>    
                        </div>        
                          
                        <div style={{marginLeft:"15px"}}>
                            <label>Cor do Fluxo</label>
                            <SketchPicker color={color} onChangeComplete={color => setColor(color.hex)}/>    
                        </div>                              
                    </div>
                    
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-attendance' className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}