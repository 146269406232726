import React, {useState, useEffect} from 'react'

import { ValidationForm } from 'react-bootstrap4-form-validation';
import ContentWrapper from '../layouts/contentWrapper'

import {  format } from 'date-fns'

import ShowMessages from '../../components/Exceptions';
import {  Nav, NavLink, NavItem, TabPane, TabContent, Button} from 'reactstrap';
import Loading from '../../components/Loading';
import setStatistic from '../../services/apiStatistic'

import api from '../../services/api';
import loadingButton  from '../layouts/Buttons'

export default function SchedulingSettings({ id}) {
    const [loading, setLoading]                               = useState(false)
    const [loadingInternal, setLoadingInternal]               = useState(false)
    const [tabActive, setTabActive]                           = useState(0)

    const [schedulingView, setSchedulingView]                 = useState(0)
    const [specialistView, setSpecialistView]                 = useState(0)
    const [specialist, setSpecialist]                         = useState(0)
    const [servicetime, setServiceTime]                       = useState(1)
    const [startDateBlocked, setStartDateBlocked]             = useState('')
    const [endDateBlocked, setEndDateBlocked]                 = useState('')

    const [checkBlocked, setCheckBlocked]                     = useState(false)

    const [dataDoctors, setDataDoctors]                       = useState([])
    const [dataTypeView, setDataTypeView]                     = useState([])
    const [dataServiceTime, setDataServiceTime]               = useState([])
    const [events, setEvents]                                 = useState([])
    
    const getSettings = async(id) => {
        setLoadingInternal(true)
        await api.get('/schedule-settings/' + id)
                 .then(response => {
                    if (response.ok) {     
                        if (response.data[0].events === null) {
                            let _aux = []

                            Array.from(document.querySelectorAll('input[type="time"]')).map((item) => {
                                _aux.push({ name  : item.name,
                                            value : ''})
                            })
                            setEvents(_aux)
                        } else
                            setEvents(response.data[0].events)
                        
                        setSchedulingView(response.data[0].idschedulingview)            
                        setSpecialistView(response.data[0].specialistView)
                        setServiceTime(response.data[0].idservicetime)
                        if ((response.data[0].startDateBlocked !== null) && (response.data[0].endDateBlocked !== null)){
                            setStartDateBlocked(format(new Date(response.data[0].startDateBlocked),"yyyy-MM-dd"))
                            setEndDateBlocked(format(new Date(response.data[0].endDateBlocked),"yyyy-MM-dd"))  
                            setCheckBlocked(true)  
                        } else {
                            setStartDateBlocked('')
                            setEndDateBlocked('')  
                            setCheckBlocked(false)  
                        }

                        setLoadingInternal(false) 
                    } 
                })
                .catch( error => {
                    ShowMessages(error) 
                })

    }


    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-scheduling-settings'),document.querySelector('#submit-scheduling-settings').children[0].classList,document.querySelector('#submit-scheduling-settings').lastChild.nodeValue)
        event.preventDefault()

        try {
            let _data = {specialist,
                         schedulingView,
                         servicetime,
                         specialistView,
                         startDateBlocked,
                         endDateBlocked,
                         events}

            await api.post('/schedule-settings', _data)
                         .then(response => {
                             ShowMessages(response.data)  
                             loadingButton(false,document.querySelector('#submit-scheduling-settings')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-scheduling-settings'))
                            ShowMessages(error) 
                        })
                 
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-scheduling-settings'))
            ShowMessages(response)
        }
    }

    const handleSelectSpecialist = async(value) => {
        setSpecialist(value)
        if (value != '')
            getSettings(value)
        else {
            setEvents([])
            setSchedulingView('')            
            setSpecialistView('')
            setServiceTime('')
            setStartDateBlocked('')
            setEndDateBlocked('')  
            setCheckBlocked(false) 
        }
    }

    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getScheduleSettingsData')
                     .then(response => {
                        if (response.ok) {
                            setDataDoctors(response.data.employee)
                            setDataTypeView(response.data.typeCalendarView)
                            setDataServiceTime(response.data.servicetime)
                        } else 
                            ShowMessages(response)
                     })
                     .catch( error => {
                        ShowMessages(error)
                      })
            
            setLoading(false)         
        } catch (response) {
            ShowMessages(response)    
        }
    }
  
    const handleEvent = (value, name) => {
        let _aux = []
        events.map((item) => {
            if (item.name === name)
                _aux.push({ name,
                            value })
            else
                _aux.push(item)
        })
        setEvents(_aux)

    }

    const getEvent = (name) => {
        let event 
        event = events.filter((item) => {
            return(item.name === name)
        })

        return event[0] === undefined ? '' : event[0].value
    }

    useEffect(() => {
        getData()
        setStatistic('maintenance\\schedule-settings')
    },[])

    return (
        <ContentWrapper>   
            <Loading loading={loading} />
            <div className={`row ${(loading ? "hidden" : "")}`} style={{justifyContent:"center", padding:"0px 18px"}}>
                <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                    <div style={{display:'flex',width:50 +"%"}}>
                        <div style={{width:100 +"%"}}>
                            <label>Especialista</label>
                            <select
                                id="specialist"
                                name="specialist"
                                required
                                className="form-select"
                                value={specialist} 
                                onChange={e => handleSelectSpecialist(e.target.value)}>
                                <option value="">Selecione</option>
                                {dataDoctors.map((_item, key) => {
                                    return(<option key={key} value={_item.value}>{_item.label}</option>)
                                })}
                            </select>
                       </div>  
                    </div>
                    <div style={{marginTop:"15px"}} className={`${(specialist <= 0 || specialist === "" ? "hidden" : "")}`}>
                        <Loading loading={loadingInternal} addClass="heigth70vh"/>
                        <div className={`${loadingInternal ? "hidden" : ""}`}>
                            <Nav tabs>
                                <NavItem>
                                    <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                        <span id="personal-data">Configurações Gerais</span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                        <span id="personal-data">Configurações de Horários</span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={tabActive}>
                                <TabPane tabId={0}>
                                    <div className={`default-carder ${specialist > 0 ? '' : 'hidden'}`} style={{width:50 + "%", marginTop:"20px"}}>
                                        <div style={{width:100 + "%"}}>
                                            <div style ={{display: "flex"}}>
                                                <div title="Ao abrir a sua agenda, como você gostaria de vê-la?" style={{width:50 + "%"}}>
                                                    <label>Visualização da Agenda</label>
                                                    <select
                                                        id="schedulingview"
                                                        name="schedulingview"
                                                        required
                                                        className="form-select"
                                                        value={schedulingView} 
                                                        onChange={e => setSchedulingView(e.target.value)}>
                                                        <option value="">Selecione</option>
                                                        {dataTypeView.map((_item, key) => {
                                                            return(<option key={key} value={_item.value}>{_item.label}</option>)
                                                        })}
                                                    </select>
                                                </div>  
                                                <div style={{marginLeft:'15px', width:50 + "%"}}>
                                                    <label>Tempo de Consulta</label>
                                                    <select
                                                        id="servicetime"
                                                        name="servicetime"
                                                        required
                                                        className="form-select"
                                                        value={servicetime} 
                                                        onChange={e => setServiceTime(e.target.value)}>
                                                        <option value="">Selecione</option>
                                                        {dataServiceTime.map((_item, key) => {
                                                            return(<option key={key} value={_item.value}>{_item.label}</option>)
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                            
                                            <div style={{display:'flex'}}>
                                                <div title="Ao abrir a sua agenda, você gostária de ver todos os profissionais, ou apenas os selecionados?" style={{width:100 + '%', padding: "5px", border: "1px solid #ced4da", margin: "13px 0px 5px 0px", borderRadius: "5px"}}>
                                                    <label style={{marginTop: "-15px", position: "absolute", background: "#fff"}}>Visualização do Profissional</label>
                                                    <div style={{display:"flex", marginTop:"5px "}}>
                                                        <div className='box-radio'>
                                                            <input type="radio" className="form-check-input" style={{marginLeft:"0px"}} checked={specialistView === 0} onChange={e => {setSpecialistView(0)}}/>
                                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Somente selecionados</span>
                                                        </div>  
                                                        <div className='box-radio'>
                                                            <input type="radio" className="form-check-input" style={{marginLeft:"0px"}} checked={specialistView === 1} onChange={e => {setSpecialistView(1)}}/>
                                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Todos</span>
                                                        </div>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div>            
                                                <div style={{width:100 + '%'}}>
                                                    <div>
                                                        <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} value={checkBlocked} checked={checkBlocked} onChange={e => setCheckBlocked(!checkBlocked)}/>
                                                        <span style={{marginLeft:"17px", marginTop:"4px"}}>Bloquear Agenda?</span>
                                                    </div>
                                                    <div style={{display:"flex", marginTop:"3px"}}>
                                                        <input type="date" className='form-control' value={startDateBlocked} onChange={e => setStartDateBlocked(e.target.value)} disabled={!checkBlocked}/>
                                                        <span style={{marginLeft:"15px", marginRight:"15px", marginTop:"12px"}}>até</span>
                                                        <input type="date" className='form-control' value={endDateBlocked} onChange={e => setEndDateBlocked(e.target.value)} disabled={!checkBlocked}/>
                                                    </div>
                                                </div>      
                                            </div>     
                                        </div>
                                    </div>
                                </TabPane>
                                <TabPane tabId={1}>
                                    <div style={{display:"flex"}}>
                                        <table>
                                            <thead className="thead-theme">
                                                <tr className="head-th">
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px",backgroundColor: "#fff"}} className="head-th"></td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th" >Segunda-Feira</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Terça-Feira</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Quarta-Feira</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Quinta-Feira</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Sexta-Feira</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Sábado</td>
                                                    <td style={{width:'auto', textAlign:"center"}}className="head-th">Domingo</td>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Primeiro Turno | Inicio</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Seg' value={getEvent('T1_Ent_Seg')} onChange={e => handleEvent(e.target.value,'T1_Ent_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Ter' value={getEvent('T1_Ent_Ter')} onChange={e => handleEvent(e.target.value,'T1_Ent_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Qua' value={getEvent('T1_Ent_Qua')} onChange={e => handleEvent(e.target.value,'T1_Ent_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Qui' value={getEvent('T1_Ent_Qui')} onChange={e => handleEvent(e.target.value,'T1_Ent_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Sex' value={getEvent('T1_Ent_Sex')} onChange={e => handleEvent(e.target.value,'T1_Ent_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Sab' value={getEvent('T1_Ent_Sab')} onChange={e => handleEvent(e.target.value,'T1_Ent_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Ent_Dom' value={getEvent('T1_Ent_Dom')} onChange={e => handleEvent(e.target.value,'T1_Ent_Dom')}/></td>
                                                </tr>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Primeiro Turno | Fim</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Seg' value={getEvent('T1_Sai_Seg')} onChange={e => handleEvent(e.target.value,'T1_Sai_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Ter' value={getEvent('T1_Sai_Ter')} onChange={e => handleEvent(e.target.value,'T1_Sai_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Qua' value={getEvent('T1_Sai_Qua')} onChange={e => handleEvent(e.target.value,'T1_Sai_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Qui' value={getEvent('T1_Sai_Qui')} onChange={e => handleEvent(e.target.value,'T1_Sai_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Sex' value={getEvent('T1_Sai_Sex')} onChange={e => handleEvent(e.target.value,'T1_Sai_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Sab' value={getEvent('T1_Sai_Sab')} onChange={e => handleEvent(e.target.value,'T1_Sai_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T1_Sai_Dom' value={getEvent('T1_Sai_Dom')} onChange={e => handleEvent(e.target.value,'T1_Sai_Dom')}/></td>
                                                </tr>
                                                <tr className="head-th" style={{fontSize: "7px"}}>
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                </tr>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Segundo Turno | Inicio</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Seg' value={getEvent('T2_Ent_Seg')} onChange={e => handleEvent(e.target.value,'T2_Ent_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Ter' value={getEvent('T2_Ent_Ter')} onChange={e => handleEvent(e.target.value,'T2_Ent_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Qua' value={getEvent('T2_Ent_Qua')} onChange={e => handleEvent(e.target.value,'T2_Ent_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Qui' value={getEvent('T2_Ent_Qui')} onChange={e => handleEvent(e.target.value,'T2_Ent_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Sex' value={getEvent('T2_Ent_Sex')} onChange={e => handleEvent(e.target.value,'T2_Ent_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Sab' value={getEvent('T2_Ent_Sab')} onChange={e => handleEvent(e.target.value,'T2_Ent_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Ent_Dom' value={getEvent('T2_Ent_Dom')} onChange={e => handleEvent(e.target.value,'T2_Ent_Dom')}/></td>
                                                </tr>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Segundo Turno | Fim</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Seg' value={getEvent('T2_Sai_Seg')} onChange={e => handleEvent(e.target.value,'T2_Sai_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Ter' value={getEvent('T2_Sai_Ter')} onChange={e => handleEvent(e.target.value,'T2_Sai_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Qua' value={getEvent('T2_Sai_Qua')} onChange={e => handleEvent(e.target.value,'T2_Sai_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Qui' value={getEvent('T2_Sai_Qui')} onChange={e => handleEvent(e.target.value,'T2_Sai_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Sex' value={getEvent('T2_Sai_Sex')} onChange={e => handleEvent(e.target.value,'T2_Sai_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Sab' value={getEvent('T2_Sai_Sab')} onChange={e => handleEvent(e.target.value,'T2_Sai_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T2_Sai_Dom' value={getEvent('T2_Sai_Dom')} onChange={e => handleEvent(e.target.value,'T2_Sai_Dom')}/></td>
                                                </tr>
                                                <tr className="head-th" style={{fontSize: "7px"}}>
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                    <td style={{width:'auto'}}className="head-th">&nbsp;</td>
                                                </tr>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Terceiro Turno | Inicio</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Seg' value={getEvent('T3_Ent_Seg')} onChange={e => handleEvent(e.target.value,'T3_Ent_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Ter' value={getEvent('T3_Ent_Ter')} onChange={e => handleEvent(e.target.value,'T3_Ent_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Qua' value={getEvent('T3_Ent_Qua')} onChange={e => handleEvent(e.target.value,'T3_Ent_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Qui' value={getEvent('T3_Ent_Qui')} onChange={e => handleEvent(e.target.value,'T3_Ent_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Sex' value={getEvent('T3_Ent_Sex')} onChange={e => handleEvent(e.target.value,'T3_Ent_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Sab' value={getEvent('T3_Ent_Sab')} onChange={e => handleEvent(e.target.value,'T3_Ent_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Ent_Dom' value={getEvent('T3_Ent_Dom')} onChange={e => handleEvent(e.target.value,'T3_Ent_Dom')}/></td>
                                                </tr>
                                                <tr className="head-th" >
                                                    <td style={{width:"225px", minWidth:"175px", maxWidth:"175px"}} className="head-th">Terceiro Turno | Fim</td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Seg' value={getEvent('T3_Sai_Seg')} onChange={e => handleEvent(e.target.value,'T3_Sai_Seg')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Ter' value={getEvent('T3_Sai_Ter')} onChange={e => handleEvent(e.target.value,'T3_Sai_Ter')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Qua' value={getEvent('T3_Sai_Qua')} onChange={e => handleEvent(e.target.value,'T3_Sai_Qua')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Qui' value={getEvent('T3_Sai_Qui')} onChange={e => handleEvent(e.target.value,'T3_Sai_Qui')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Sex' value={getEvent('T3_Sai_Sex')} onChange={e => handleEvent(e.target.value,'T3_Sai_Sex')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Sab' value={getEvent('T3_Sai_Sab')} onChange={e => handleEvent(e.target.value,'T3_Sai_Sab')}/></td>
                                                    <td style={{width:'auto'}}className="head-th"><input type="time" className='form-control' name='T3_Sai_Dom' value={getEvent('T3_Sai_Dom')} onChange={e => handleEvent(e.target.value,'T3_Sai_Dom')}/></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </TabPane>
                            </TabContent> 
                        </div>
                    </div>    
                       
                    <div className="modal-footer footer-fixed modal-footer-bottom-10" style={{border:'0px'}}>
                        <Button type="submit" id="submit-scheduling-settings" color="primary" className={`${loading ? "hidden" : ""}`}>
                            <em className="fa fa-save"></em> Salvar
                        </Button>
                        <Button className="btn-ligth-gray" onClick={e => window.removeTab(`tab_${id}`,e)}>
                            <em className="fa fa-door-closed"></em> Fechar
                        </Button>
                    </div>  
                </ValidationForm> 
            </div>
        </ContentWrapper>
    )
}