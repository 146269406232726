import React from 'react'
import { isAuthenticated } from './services/auth'

import Employees         from './pages/maintenance/employees'
import Company           from './pages/maintenance/company'
import Profile           from './pages/maintenance/profile'
import Bill              from './pages/maintenance/bill'
import User              from './pages/maintenance/users'
import UserGroup         from './pages/maintenance/user-group'
import Occupations       from './pages/maintenance/occupation'
import PermissionsGroup  from './pages/maintenance/permissions-group'
import PermissionsUser   from './pages/maintenance/permissions-user'
import Schedule          from './pages/maintenance/schedule'
import Patient           from './pages/maintenance/patients'
import Agreement         from './pages/maintenance/agreements'
import AttendanceFlux    from './pages/maintenance/attendance-flux'
import Cid10             from './pages/maintenance/cid10'
import Procedure         from './pages/maintenance/procedures'
import HealthInsurance   from './pages/maintenance/health-insurance'
import Alert             from './pages/maintenance/alert'
import AlertAll          from './pages/maintenance/alerts-all'
import NewsletterSystem  from './pages/maintenance/newsletter-system'
import MedicalCare       from './pages/maintenance/medical-care'
import SchedulingConfig  from './pages/maintenance/schedule-settings'
import Error404          from './pages/errors/404' 
import ReportBill        from './pages/reports/report-bill'
import Providers         from './pages/maintenance/providers'
import BillsToPay        from './pages/maintenance/billstopay'
import CostCenter        from './pages/maintenance/cost-center'
import ChartOfAccounts   from './pages/maintenance/chart-of-accounts'
import PaymentConditions from './pages/maintenance/payment-conditions'
import Departament       from './pages/maintenance/departament'

const getObject = (uri, id, props, idmenu) => {
    switch (uri) {
        case '/employees'             : return(<Employees/>)   
        case '/company'               : return(<Company/>)   
        case '/profile'               : return(<Profile/>)   
        case '/users'                 : return(<User/>)   
        case '/user-groups'           : return(<UserGroup/>)   
        case '/bill'                  : return(<Bill/>)   
        case '/occupations'           : return(<Occupations/>)
        case '/shedule'               : return(<Schedule/>)
        case '/patients'              : return(<Patient/>)
        case '/agreements'            : return(<Agreement/>)
        case '/procedures'            : return(<Procedure/>)
        case '/attendance-flux'       : return(<AttendanceFlux/>)
        case '/health-insurance'      : return(<HealthInsurance/>)
        case '/medical-care'          : return(<MedicalCare/>)
        case '/cid10'                 : return(<Cid10/>)
        case '/alert-all'             : return(<AlertAll/>)
        case '/newsletter-system'     : return(<NewsletterSystem/>)
        case '/provider'              : return(<Providers/>)
        case '/billstopay'            : return(<BillsToPay/>)
        case '/cost-centers'          : return(<CostCenter/>)
        case '/chart-of-accounts'     : return(<ChartOfAccounts/>)
        case '/payment-conditions'    : return(<PaymentConditions/>)
        case '/shedule-config'        : return(<SchedulingConfig id={id} props={props}/>)
        case '/alert'                 : return(<Alert id={id} props={props}/>)
        case '/usergroup/permissions' : return(<PermissionsGroup id={id} props={props}/>)
        case '/user/permissions'      : return(<PermissionsUser id={id} props={props}/>)
        case '/receipt'               : return(<ReportBill id={id} props={props}/>)
        case '/departament'           : return(<Departament/>)
        default                       : return(<Error404/>) 
    }
}

export default function getComponent(uri,id,props,idmenu) {
    return ( isAuthenticated ? getObject(uri, id, props,idmenu) : <Error404/> )
    
}

 