import React from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { TransitionGroup } from 'react-transition-group';

import Login          from './pages/login/login'
import Forgot         from './pages/login/forgot'
import UpdatePassword from './pages/login/updatePassword'
import BasePage       from "./pages/layouts/BasePage"
import Error404       from './pages/errors/404'
import Register       from './pages/login/register'
import Payment        from './pages/login/payment'

import { isAuthenticated } from './services/auth'

const PrivateRoute = ({  children }) => {
    return isAuthenticated() ? children : <Navigate to="/login" />;
  };

const BaseRoutes = () => {
    return (
        <TransitionGroup>
            <BrowserRouter>
                <Routes >
                    <Route exact path="/" element={<PrivateRoute> 
                                                       <BasePage/>
                                                   </PrivateRoute>} /> 
                    <Route exact path="/login" element={<Login/>} />
                    <Route exact path="/payment" element={<PrivateRoute> 
                                                              <Payment/>
                                                          </PrivateRoute>} /> 
                    <Route exact path="/register" element={<Register/>} />
                    <Route exact path="/forgot/:hash" element={<UpdatePassword/>} />
                    <Route exact path="/forgot" element={<Forgot/>} />
                    <Route path="/*" element={<Error404/>} />
                </Routes>
            </BrowserRouter>
        </TransitionGroup>
    )
}

export default BaseRoutes;