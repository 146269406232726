import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import api from '../../services/api';
import CurrencyInput from './../../components/moneyInput';
import setStatistic from '../../services/apiStatistic'
import loadingButton  from './../layouts/Buttons'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalProcedure({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                = useState(false)
    const [tabActive, setTabActive]            = useState(0)

    const [code,setCode]                       = useState('')
    const [terminology,setTerminology]         = useState('')
    const [resolution,setResolution]           = useState('')
    const [group,setGroup]                     = useState('')
    const [subgroup,setSubgroup]               = useState('')
    const [chapter,setChapter]                 = useState('')
    const [servicetime, setServicetime]        = useState(0)
    const [dut,setDut]                         = useState('')
    const [priority,setPriority]               = useState(false)
    const [correlation,setCorrelation]         = useState(false)
    const [od,setOd]                           = useState(false)
    const [amb,setAmb]                         = useState(false)
    const [hco,setHco]                         = useState(false)
    const [hso,setHso]                         = useState(false)
    const [pac,setPac]                         = useState(false)
    const [procedureValue, setProcedureValue]  = useState(0)
    const [recommendations,setRecommendations] = useState('')
    const [comments,setComments]               = useState('')
    const [active,setActive]                   = useState(false)

    
    const checkData = (event, formData, errorInputs) => {
        if ((formData.code             === "") ||
            (formData.servicetime      === "") || 
            (formData.procedureValue   === "") ||
            (formData.activeProcedure  === "") || 
            (formData.terminology      === "") || 
            (formData.resolution       === "") ||
            (formData.group            === "") ||
            (formData.subgroup         === "") ||
            (formData.chapter          === "")) {
            document.querySelector('#general').style.color = "#dc3545"
            document.querySelector('#general').parentElement.click()
        } else 
            document.querySelector('#general').style.color = "#656565"
    }

    const setData = (obj,clear) => {
        setLoading(true)
        setCode(clear ? '' : obj.code) 
        setTerminology(clear ? '' : obj.terminology) 
        setResolution(clear ? '' : obj.resolution) 
        setGroup(clear ? '' : obj.group) 
        setSubgroup(clear ? '' : obj.subgroup) 
        setChapter(clear ? '' : obj.chapter) 
        setDut(clear ? '' : obj.dut) 
        setPriority(clear ? '' : obj.priority) 
        setCorrelation(clear ? '' : obj.correlation) 
        setOd(clear ? false : obj.od) 
        setAmb(clear ? false : obj.amb) 
        setHco(clear ? false : obj.hco) 
        setHso(clear ? false : obj.hso) 
        setPac(clear ? false : obj.pac) 
        setRecommendations(clear ? '' : obj.recommendations) 
        setComments(clear ? '' : obj.comments) 
        setActive(clear ? false : obj.active)
        setProcedureValue(clear ? 0 : obj.procedurevalue.replace('.',',')) 
        setLoading(false)
    }

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-procedure'),document.querySelector('#submit-procedure').children[0].classList,document.querySelector('#submit-procedure').lastChild.nodeValue)
        event.preventDefault()

        try {
             let _data = {code,
                          terminology,
                          resolution,
                          group,
                          subgroup,
                          chapter,
                          dut,
                          priority,
                          correlation,
                          od,
                          amb,
                          hco,
                          hso,
                          pac,
                          recommendations,
                          comments,
                          active,
                          servicetime,
                          procedureValue}

            if (editState)
                await api.put('/procedure/' + data.idtuss, _data)
                         .then(response => {
                            ShowMessages(response)
                            if (response.ok) {
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-procedure'))
                            } else 
                                loadingButton(false,document.querySelector('#submit-procedure')) 
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-procedure'))
                            ShowMessages(error) 
                        })
            else
                await api.post('/procedure', _data)
                         .then(response => {
                            ShowMessages(response)
                             if (response.ok) {   
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else
                                    loadingButton(false,document.querySelector('#submit-procedure'))         
                             } else 
                                loadingButton(false,document.querySelector('#submit-procedure')) 
                          })
                          .catch( error => {
                            loadingButton(false,document.querySelector('#submit-procedure'))
                            ShowMessages(error) 
                          })
            
        } catch (response) {
            loadingButton(false,document.querySelector('#submit-procedure'))
            ShowMessages(response)
        }
    }
    
    useEffect(() => {
        if (editState) 
            setData(data,false)
        else 
            setData({},true)
    },[data,editState,showModal])
   
    useEffect(() => {
        setStatistic('modals\\modal-procedure')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogClassName="height600 width775" dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content" onErrorSubmit={checkData}> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Propriedades de Procedimento | TUSS
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading}/>
                    <div className={`${loading ? "hidden" : ""}`}> 
                        <Nav tabs>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    <span id="general">Informações Principais</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    <span id="documentos">Informações Adicionais</span>
                                </NavLink>
                            </NavItem> 
                        </Nav>
                        <TabContent activeTab={tabActive}>
                            <TabPane tabId={0}>
                                <div style={{display:'flex'}}>
                                    <div style={{width:25 + "%"}}>
                                        <label>Código</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="code"
                                                id="code"
                                                type="text"
                                                required
                                                autoFocus={true}
                                                maxLength={8}
                                                errorMessage={{ required: "Por favor, informe um código!" }}
                                                value={code}
                                                className="form-control "
                                                onChange={e => setCode(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                    </div>
                                    <div style={{width:25 + "%", marginLeft:"15px"}}>
                                        <label>Tempo do Procedimento</label>
                                        <div className="input-group">
                                            <TextInput  
                                                name="servicetime"
                                                id="servicetime"
                                                type="number"
                                                required
                                                autoFocus={true}
                                                maxLength={8}
                                                errorMessage={{ required: "Por favor, informe o tempo de exxecução do procedimento!" }}
                                                value={servicetime}
                                                className="form-control "
                                                onChange={e => setServicetime(e.target.value)}
                                                autoComplete="off"/>
                                        </div>
                                        <small>(em minutos)</small>
                                    </div>
                                    <div style={{width:25 + "%", marginLeft:"15px"}}>
                                        <label>Valor do Procedimento</label>
                                        <div className="input-group">
                                            <CurrencyInput  
                                                name="procedureValue"
                                                id="procedureValue"
                                                required
                                                errorMessage={{ required: "Por favor, informe um valor" }}
                                                value={procedureValue}
                                                className="form-control text-right"
                                                onChange={setProcedureValue}
                                                autoComplete="off"
                                            />
                                        </div>
                                    </div>
                                    <div style={{width:25 + "%", marginLeft:"15px"}}>
                                        <label>Ativar o procedimento?</label>
                                        <select 
                                            name="activeProcedure" 
                                            id="activeProcedure" 
                                            placeholder="Selecione" 
                                            value={active} 
                                            className="form-select"
                                            onChange={e => setActive(e.target.value)}>   
                                                <option value="0">Não</option>
                                                <option value="1">Sim</option>
                                        </select>
                                    </div>
                                </div>
                                <div>
                                    <label>Terminologia</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="terminology"
                                            id="terminology"
                                            type="text"
                                            required
                                            row={3}
                                            multiline 
                                            maxLength={500}
                                            errorMessage={{ required: "Por favor, informe uma terminologia!" }}
                                            value={terminology}
                                            className="form-control "
                                            onChange={e => setTerminology(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>  
                                <div>
                                    <label>Resolução</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="resolution"
                                            id="resolution"
                                            type="text"
                                            required
                                            row={3}
                                            multiline 
                                            maxLength={500}
                                            errorMessage={{ required: "Por favor, informe uma resolução!" }}
                                            value={resolution}
                                            className="form-control "
                                            onChange={e => setResolution(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div> 
                                <div>
                                    <label>Grupo</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="group"
                                            id="group"
                                            type="text"
                                            required
                                            maxLength={250}
                                            errorMessage={{ required: "Por favor, informe um grupo!" }}
                                            value={group}
                                            className="form-control "
                                            onChange={e => setGroup(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div> 
                                <div>
                                    <label>SubGrupo</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="subgroup"
                                            id="subgroup"
                                            type="text"
                                            required
                                            maxLength={250}
                                            errorMessage={{ required: "Por favor, informe um subgrupo!" }}
                                            value={subgroup}
                                            className="form-control "
                                            onChange={e => setSubgroup(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>  
                                <div>
                                    <label>Capítulo</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="chapter"
                                            id="chapter"
                                            type="text"
                                            required
                                            maxLength={250}
                                            errorMessage={{ required: "Por favor, informe um capítulo!" }}
                                            value={chapter}
                                            className="form-control "
                                            onChange={e => setChapter(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div> 
                            </TabPane>
                            <TabPane tabId={1}>
                                <div>
                                    <label>Dut</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="dut"
                                            id="dut"
                                            type="text"
                                            maxLength={250}
                                            value={dut}
                                            className="form-control "
                                            onChange={e => setDut(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div> 
                                <div>
                                    <label>Recomendações</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="recommendation"
                                            id="recommendation"
                                            type="text"
                                            row={4}
                                            multiline 
                                            maxLength={1500}
                                            value={recommendations}
                                            className="form-control "
                                            onChange={e => setRecommendations(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>  
                                <div>
                                    <label>Comentários</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="comments"
                                            id="comments"
                                            type="text"
                                            row={4}
                                            multiline 
                                            maxLength={1500}
                                            value={comments}
                                            className="form-control "
                                            onChange={e => setComments(e.target.value)}
                                            autoComplete="off"/>
                                    </div>
                                </div>  
                                <div style={{display:'flex', marginTop:"15px", marginBottom:"15px"}}>
                                    <div style={{display:'flex',flexWrap: "wrap"}}> 
                                        <div style={{display:"flex", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={priority} onChange={e => {setPriority(!priority)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Prioritário</span>
                                        </div>   
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={correlation} onChange={e => {setCorrelation(!correlation)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Correlação</span>
                                        </div>  
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={od} onChange={e => {setOd(!od)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Od</span>
                                        </div> 
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={amb} onChange={e => {setAmb(!amb)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Amb</span>
                                        </div>   
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={hco} onChange={e => {setHco(!hco)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Hco</span>
                                        </div>    
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={hso} onChange={e => {setHso(!hso)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Hso</span>
                                        </div>  
                                        <div style={{display:"flex", marginLeft:"15px", alignItems : "center"}}>
                                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={pac} onChange={e => {setPac(!pac)}}/>
                                            <span style={{marginLeft:"17px", marginTop:"4px"}}>Pac</span>
                                        </div>  
                                    </div> 
                                </div>
                            </TabPane>
                        </TabContent>                 
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id='submit-procedure' className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Salvar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}