import React, { useState, useEffect } from 'react';

import ContentWrapper from '../layouts/contentWrapper'
import Loading from '../../components/Loading';

import { startOfMonth, endOfMonth, format } from 'date-fns'

import {checkPermission} from '../../components/security'
import Calendar  from '../../components/Calendar'

import ModalScheduling from '../modals/modal-scheduling'
import ModalSchedulingOptions from '../modals/modal-scheduling-options'
import ModalWaitingList from '../modals/modal-waiting-list'

import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'

import { IDUSER, ReadDataStorage} from './../../services/auth'

export default function Schedule() {
    const [loading, setLoading]                   = useState(false)
    const [data, setData]                         = useState(null)
    const [dataPatient, setDataPatient]           = useState(null)
    const [dataWaiting, setDataWaiting]           = useState(null)
    const [flux, setFlux]                         = useState([])
    const [print, setPrint]                       = useState(false)
    const [showModal, setShowModal]               = useState(false)
    const [showModalOption, setShowModalOption]   = useState(false)
    const [showModalWaiting, setShowModalWaiting] = useState(false)
    const [editState, setEditState]               = useState(false)
    const [dateActual, setDateActual]             = useState(null) 

    const [totalWaiting, setTotalWaiting]         = useState(0)

    const [configs, setConfigs]                   = useState([])
    const [events, setEvents]                     = useState([])

    let _time = new Date();
        _time = _time.getHours() + ':' + _time.getMinutes() ;
    
    const handleReload = async() => {
        if (dateActual)
            getData(dateActual)
    }   

    const showAttendance = async(_id) => {
        setShowModalOption(true)
        await api.get('/schedule/' + _id)
                 .then(response => {
                    setDataPatient(response.data)
                 })
                 .catch( error => {
                     ShowMessages(error)
                 })
    }

    const handleNewSchedule = async(isCallback, event) => {
        if (checkPermission(20,2)) {
            ShowMessages( {data : {
                                    message : 'Você não tem permissão para executar está ação, caso necessário, entre em contato com seu supervisor!',
                                    status  : 401}
                              }
                          )
        } else {
            if (isCallback) {
                setEditState(true)
                setData(event)
            } else {
                setEditState(false)
                setData({})
            }
            setShowModal(true)
            
        }
    }

    const getData = async(_date) => {
        setLoading(true)

        let _filters = [{field  : 'scheduling',
                         key    : 'between',  
                         value  : startOfMonth(new Date(_date)),
                         value2 : endOfMonth(new Date(_date)),   
                        }]

                       
        await api.get('/schedule/' + ReadDataStorage(IDUSER) + '/' + btoa(JSON.stringify(_filters)))
                 .then(response => {
                    if (response.data != null) {
                        let _aux = []
                        setTotalWaiting(response.data._totalWaiting)
                        setFlux(response.data._attendance)
                        response.data._professional.map((item) => {
                            return (_aux.push({ id            : item.value, 
                                                title         : item.label,
                                                subtitle      : item.specialist + ' | ' + item.office,
                                                interval      : 15,
                                                start         : '08:00',
                                                showMaximized : true,
                                                showWeek      : true,
                                                end           : '18:00'}))
                        })                           
                        setConfigs(_aux)

                        _aux = []
                    
                        response.data._scheduling.map((item) => {
                            let _alerts = []

                            if (item.people_scheduling.type !== undefined)
                                if (item.people_scheduling.type)
                                    _alerts.push({icon   : 'fa fa-registered',
                                                  legend : 'Retorno'})

                            if (item.people_scheduling.telemedicine !== undefined)
                                if (item.people_scheduling.telemedicine)
                                    _alerts.push({icon   : 'fa fa-trademark text-warning',
                                                  legend : 'TeleMedicina'})                     

                            return (_aux.push({ id         : item.idscheduling, 
                                                color      : item.style.background,
                                                parent     : parseInt(item.idprofessional), 
                                                hour       : format(new Date(item.scheduling),'HH:mm'), 
                                                date       : format(new Date(item.scheduling),'yyyy-MM-dd'), 
                                                name       : item.people_scheduling.name + ' | ' + item.people_scheduling.phone + ' | ' + item.healthinsurance, 
                                                alerts     : _alerts }))
                        })

                        setEvents(_aux)
                    }
                    setLoading(false)
                })
                .catch( error => {
                    setLoading(false)
                    ShowMessages(error)
                })
    }

    const handleModalWaiting = async() => {
        setShowModalWaiting(true)
    }

    const reschedule = async (_id) => {
        setData(dataPatient)
        setEditState(true)
        setShowModalOption(false)
        setShowModal(true)
    }

    useEffect(() => {
        setDateActual(startOfMonth(new Date()))
    },[])

    useEffect(() => {
        if (dateActual)
            getData(dateActual)
    },[dateActual])

    const handleCloseModal        = () => {setShowModal(false)}
    const handleCloseModalOption  = () => {setShowModalOption(false)}
    const handleCloseModalWaiting = () => {setShowModalWaiting(false)}

    return (
            <ContentWrapper>
                <Loading loading={loading}/>
                <ModalScheduling showModal={showModal} handleCloseModal={handleCloseModal} editState={editState} data={data} handleReload={handleReload}/>
                <ModalSchedulingOptions showModal={showModalOption} handleCloseModal={handleCloseModalOption} data={dataPatient} handleReload={handleReload} handleReScheduling={reschedule}/>
                <ModalWaitingList showModal={showModalWaiting} handleCloseModal={handleCloseModalWaiting}  handleReload={handleReload}/>

                <div className={`box-schedule content-box-cart ${(loading ? "hidden" : "")}`}>
                    <div style={{display:"flex"}}>
                        <button className='btn btn-success' title="Insere um novo agendamento" onClick={e => handleNewSchedule()}><i className='fa fa-plus'/></button>
                        <div>
                            <button className='btn btn-info' style={{marginLeft:"3px"}} title="Lista de Espera" onClick={e => handleModalWaiting()}><i className='fa fa-recycle'/></button>
                            {totalWaiting > 0 ? 
                                <span className="badge badge-warning" style={{position: "absolute", marginTop: "-10px", marginLeft: "-15px"}}>{totalWaiting}</span>
                            : ''}
                        </div>
                        <button className='btn btn-primary' onClick={e => getData(dateActual)} style={{marginLeft:"3px"}} title="Atualizar Agenda"><i className='fa fa-redo'/></button>
                        <button className='btn btn-dark'    style={{marginLeft:"3px"}} title="Imprimir Agenda"><i className='fa fa-print'/></button>
                    </div>
                    <Calendar configs={configs} events={events} newEvent={handleNewSchedule} editEvent={showAttendance} legend={flux}/>
                </div>
            </ContentWrapper>
            );

}



