import React, {useState, useEffect} from 'react'

import { Card, CardBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import Pagination from "react-js-pagination";
import ModalPDFViewer from "./../pages/modals/modal-pdf-viewer"

import ShowMessages from './../components/Exceptions'


import api from '../services/api'

export default function Grid({url, headers, loading, callbackReload, reload, callbackButtons, print, callbackPrint, callbackExcel, excel, callbackShowButtons, filters, filename, callbackCellClick, boxClassName, perPage, setSelecteds, getGridLayout}) {
    const [total, setTotal]           = useState(0)
    const [data, setData]             = useState([])
    const [page, setPage]             = useState(1)
    const [headerGrid, setHeaderGrid] = useState({})
    const [order, setOrder]           = useState({field:'',order:'asc'})
    const [primaryKey, setPrimaryKey] = useState('')
    const [textValue, setTextValue]   = useState('')
    const [isOpen, setIsOpen]         = useState([])
    const [checkAll, setCheckedAll]   = useState(false)

    const [checkItens, setCheckItens] = useState([])

    const handleOpen = (id) => {
        let _data = []
        if (_data['sub' + id] === undefined)
            _data['sub' + id] = true

        if  (isOpen['sub' + id] == true)
            _data['sub' + id] = false   

        setIsOpen(_data)
    }

    const getPerPage = () => {
        if (perPage !== undefined)
            return perPage
        else {
            if (getGridLayout === undefined)
                return Math.trunc((window.innerHeight - 200) / 50)
            else 
                return Math.trunc((window.innerHeight - 200) / 36)
        }
    }

    const handlePageChange = (pageNumber) => {setPage(pageNumber)}

    const getPrimaryKey    = () => {
        if (Object.keys(headers).length > 0) {
            let _primaryKey

            Object.keys(headers).map((data,idx) => {
                
                let _data = Object.values(headers)[idx]
                if((_data.primaryKey) && (_data.primaryKey === true))
                    _primaryKey = data
                    return(data)
            })
            return _primaryKey        
        }
    }

    const getTextValueKey    = () => {
        if (Object.keys(headers).length > 0) {
            let _textValueKey

            Object.keys(headers).map((data,idx) => {
                
                let _data = Object.values(headers)[idx]
                if((_data.searchDefault) && (_data.searchDefault === true))
                    _textValueKey = data
                    return(data)
            })
            return _textValueKey        
        }
    }

    const exportExcel = async() => {
        let _filters = ''
        if ((filters !== "") && (JSON.stringify(filters) !== "[]")) 
            _filters = btoa(JSON.stringify(filters))
        
        callbackExcel(false)

        await api.get(url + '/report/excel' + ((order.field === "") ? "" : '/' + order.field + '/' + order.order) + '/' + _filters)
                 .then(response => {
                    var link = document.createElement('a');
                    link.download = filename + '.xlsx';
                    link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + response.data;
                    document.body.appendChild(link);
                    link.click()
                    document.body.removeChild(link);
                 })
                 .catch( error => { 
                    ShowMessages(error)
                 })
    } 

    const handleOrders = (data, field) => {
        let _newHeaders = headerGrid

        _newHeaders[field].gridOrder = _newHeaders[field].gridOrder === 'desc' ? 'asc' : 'desc'

        let _field

        if (data.tableName)
            if (data.tableName !== '')
                _field = data.tableName + '.' + field
            else 
                _field = field
        else
            _field = field

        setOrder({ field : _field, 
                   order : _newHeaders[field].gridOrder})

        setHeaderGrid(_newHeaders)
    }

    const customButton = (props,key,data,staticMessage) => {
        switch (props.tag.toLocaleLowerCase()) {
            case "a" :
                return (<a  disabled={props.disabled} className={props.className} style={{ marginLeft: "2px", cursor: "pointer" }} title={props.title} onClick={e => callbackButtons[props.indexcallback]((props.defaultvalue ? props.defaultvalue : data[primaryKey]),data[textValue])} href={props.href.indexOf('field') >= 0 ? data[props.href.substr(6,50)] : props.href} target="_blank" rel="noopener noreferrer">{props.text}<i className={props.icon}/></a>)
            case "button" :
                return (<button disabled={props.disabled} className={props.className} style={{ marginLeft: "2px", cursor: "pointer" }} title={props.title} onClick={e => callbackButtons[props.indexcallback]((props.defaultvalue ? props.defaultvalue : data[primaryKey]),data[textValue])}><i className={props.icon}/></button>)
            
            case "dropdown" :
                    let menus = props.menus || []
                    
                    return (<Dropdown group isOpen={(isOpen['sub' + key] === true) ? true : false} size="sm" toggle={e => handleOpen(key)} title={props.title} >
                                <DropdownToggle caret className={props.className} style={{ marginLeft: "2px", cursor: "pointer" }}>
                                    <i className={props.icon}/>
                                </DropdownToggle>
                                <DropdownMenu >
                                    {menus.map((item, _itemkey) => {
                                        if (item.disabled)
                                            return(<DropdownItem disabled key={_itemkey}><i className={item.icon}/> {item.text}</DropdownItem>)
                                        else
                                            return(<DropdownItem key={_itemkey} onClick={e => callbackButtons[item.indexcallback]((item.defaultvalue ? item.defaultvalue : data[primaryKey]),data[textValue])}><i className={item.icon}/> {item.text}</DropdownItem>)
                                    })}
                                    
                                </DropdownMenu>
                            </Dropdown>
                    )
            case "checkbox" : 
                return(<label className="switch switch-sm" style={{ justifyContent: "center" }}>
                           <input type="checkbox" checked={checkItens.filter(_item => {return (_item.id === data[primaryKey])})[0].value} 
                                                  value={checkItens.filter(_item => {return (_item.id === data[primaryKey])})[0].value} 
                                                  onClick={e => handleCheck(checkItens.filter(_item => {return (_item.id === data[primaryKey])})[0].id)} />
                           <span style={{ marginTop: "5px" }}></span>
                       </label>)
   
            default:
                return (<button key={key}>Erro</button>)
        }       
    } 

    const setDataHeader = () => {
     
        let _primary   = getPrimaryKey()  
        let _textValue = getTextValueKey()

        if (_primary !== undefined)
           setPrimaryKey(_primary)

        if (_textValue !== undefined)
           setTextValue(_textValue)

        if (Object.keys(headerGrid).length <= 0) {
            {Object.keys(headers).map((header, key) => {
                headers[header].gridOrder = 'asc'
                setHeaderGrid(headers)
                return
            })}
         }
       
    }

    const getData = async() => {
        loading(true)
        setCheckedAll(false)

        let _filters = ''
        if ((filters !== "") && (JSON.stringify(filters) !== "[]")) 
            _filters = btoa(JSON.stringify(filters))

        await api.get(url + '/' + page + '/' + getPerPage()  + ((order.field === "") ? "" : '/' + order.field + '/' + order.order) + '/' + _filters)
                 .then(response => {
                     setData(response.data.data)
                     setTotal(parseInt(response.data.total))
                     let _itens = []
                     response.data.data.map(_item => {
                        _itens.push({id    : _item.idcampaign,
                                     value : false})
                     })

                     setCheckItens(_itens)
                     if (response.data.data.length > 0)
                        callbackShowButtons(true)

                     loading(false)
                 })
                 .catch( error => { 
                    loading(false)
                    ShowMessages(error)
                 })
    }

    useEffect(() => {
        setPage(1)
        getData()
    },[filters])

    useEffect(() => {
        getData()
    },[page,order])

    useEffect(() => {
        setDataHeader()
    },[ headers])

    useEffect(() => {

    },[ isOpen])

    useEffect(() => {
        if (reload) {
            getData()
            callbackReload(false)   
        }

        if (excel) 
            exportExcel()
    },[reload, excel])

    const compare = (value1,value2,operator) => {
        if (typeof value1 === 'number')
            value2 = parseFloat(value2)
        
        switch (operator) {
            case '===':
                return value1 === value2
            case '!==':
                return value1 !== value2
            case '==':
                return value1 == value2
            case '!=':
                return value1 != value2
            case '>':
                return value2 > value1
            case '<':
                return value2 < value1
            case '>=':
                return value2 >= value1
            case '<=':
                return value2 <= value1
            case 'in':
                return value1.includes(value2)
            case 'not in':
                return !value1.includes(value2)
            default:
                break;
        }
    }   

    const handleCheckAll = (value) => {
        setCheckedAll(value)
        let _id  = []
        let _aux = checkItens.map(_item => {
            _item.value = value

            if (value)
                _id.push(_item.id)
            return _item
        })
        
        setSelecteds(_id)
        setCheckItens(_aux)
    }

    const handleCheck = (name) => {
        let _id  = []
        let _aux = checkItens.map(_item => {
          if (_item.id === name)
            _item.value = !_item.value

            if (_item.value)
                _id.push(_item.id)
            return _item
        })
        setSelecteds(_id)
        setCheckItens(_aux)
    }


    if ((data !== undefined) && (data.length > 0)) {
        let _filters = ''
        if ((filters !== "") && (JSON.stringify(filters) !== "[]")) 
            _filters = btoa(JSON.stringify(filters))

        return (
            <Card className="box-card no-border no-padding-hrz">
                <ModalPDFViewer showModal={print} handleCloseModal={callbackPrint} url={url + '/report/pdf' + ((order.field === "") ? "" : '/' + order.field + '/' + order.order) + '/' + _filters}/>
                {getGridLayout === undefined ?  
                    <CardBody className={`no-border no-padding-hrz card-grid-box ${boxClassName !== undefined ? boxClassName : ''}` } style={{overflow:"auto"}}>
                        <table className="table table-striped w-100">
                            <thead className="thead-theme">
                                <tr>                     
                                    {Object.keys(headerGrid).map((header, key) => {
                                        if (headerGrid[header].visible !== undefined)
                                            if (!headerGrid[header].visible)
                                                return false
                                
                                        if (headerGrid[header]?.item !== undefined)
                                            if (headerGrid[header].item.length > 0) {
                                                if (headerGrid[header].item[0].props.tag === 'checkbox') {
                                                    return(<th key={key} className={`head-th ${headerGrid[header].className === undefined ? '' : headerGrid[header].className}`} title="Marcar | Desmarcar Todos">
                                                               <label className="switch switch-sm" style={{ justifyContent: "center" }} >
                                                                   <input type="checkbox" checked={checkAll} value={checkAll} onClick={e => handleCheckAll(!checkAll)} />
                                                                    <span style={{ marginTop: "5px" }}></span>
                                                               </label>
                                                           </th>)
                                                }
                                            }

                                        if ((headerGrid[header].order === undefined) || (headerGrid[header].order))
                                            return (<th key={key} className={`head-th ${headerGrid[header].className === undefined ? '' : headerGrid[header].className}`} >{headerGrid[header].text}<em title="Clique para ordenar o resultado" onClick={e => handleOrders(headerGrid[header],header)} className={`fa ${headerGrid[header].gridOrder === 'desc' ? 'fa-arrow-up' : 'fa-arrow-down'} arrow-order`} ></em></th>)
                                        else     
                                            return (<th key={key} className={`head-th ${headerGrid[header].className === undefined ? '' : headerGrid[header].className}`} >{headerGrid[header].text}</th>)
                                    })}
                                </tr>
                            </thead>
                            <tbody>
                                {data.map((_data, keyData) => {                                                             
                                    return (<tr className="gradeX" key={keyData} >
                                                {Object.keys(headerGrid).map((header, keyheader) => {
                                                    let value
                                                    let _class
                                                    let _staticMessage
                                                    if (headerGrid[header].visible !== undefined)
                                                        if (!headerGrid[header].visible)
                                                            return false

                                                    if ((headerGrid[header].mask) && (headerGrid[header].mask.name.toLocaleLowerCase() === 'leftslice')) 
                                                        value = '#' + (headerGrid[header].mask.value + _data[header]).slice(headerGrid[header].mask.value.length * -1)
                                                    else if (header.toLocaleLowerCase() === 'options'){
                                                        return (<td key={keyheader} className={headerGrid[header].className}>
                                                                    <div style={{ display: "flex",justifyContent:"center" }}>
                                                                        { 
                                                                        headerGrid[header].item.map((item) => {
                                                                            if (item.props.paramsMessage) {
                                                                                let _fieldNameMessage  = Object.keys(item.props.paramsMessage[0])[0]
                                                                                let _fieldValueMessage = Object.keys(item.props.paramsMessage[0])[1]

                                                                                Object.values(item.props.paramsMessage).map((_item) => {
                                                                                    if (_data[_fieldNameMessage] === _item[_fieldNameMessage])
                                                                                        _staticMessage = item.props.message.replace('{{param}}',_item[_fieldValueMessage])
                                                                                })
                                                                            }
                                                                    
                                                                            if (item.props.hiddenForId) {
                                                                                if (item.props.hiddenForId === _data[primaryKey]) 
                                                                                    return false
                                                                            }                                                                        

                                                                            if (item.props.visualConditional ) {
                                                                                let _permission = 0
                                                                                if (item.props.visualConditional.length > 0) {
                                                                                    item.props.visualConditional.map((_item1) => {
                                                                                        Object.keys(_item1).map((_item) => {
                                                                                            if (compare(_item1[_item].values,_data[_item],_item1[_item].operator)) 
                                                                                                _permission += 1
                                                                                        })
                                                                                    })
                                                                                    
                                                                                    if (_permission === Object.keys(item.props.visualConditional).length)
                                                                                        return (  
                                                                                            customButton(item.props,keyData,_data,_staticMessage)
                                                                                        )
                                                                                } else {
                                                                                    Object.keys(item.props.visualConditional).map((_item) => {
                                                                                        if (compare(item.props.visualConditional[_item].values,_data[_item],item.props.visualConditional[_item].operator)) 
                                                                                            _permission += 1
                                                                                    })

                                                                                    if (_permission === Object.keys(item.props.visualConditional).length)
                                                                                        return (  
                                                                                            customButton(item.props,keyData,_data,_staticMessage)
                                                                                        )
                                                                                }
                                                                            } else {                                                           
                                                                                return (  
                                                                                    customButton(item.props,keyData,_data,_staticMessage,_data)
                                                                                )
                                                                            }
                                                                        })}                                                                      
                                                                    </div>
                                                                </td>)
                                                    } else if (headerGrid[header].item !== undefined && headerGrid[header].item.switch !== undefined) {
                                                        _class = headerGrid[header].item.switch.data
                                                        if (_class) {
                                                            _class = _class.filter((item) => {
                                                                let _field = headerGrid[header].item.switch.key
                                                                return (item[_field] === _data[_field])
                                                            })[0]

                                                            _class = Object.values(_class)[Object.values(_class).length - 1]
                                                            value = _data[header] 
                                                        } else 
                                                            value = ''
                                                    } else 
                                                        value = _data[header]
                                                                                        
                                                    let _props = headerGrid[header].props
                                                    let _title = ''

                                                    if (_props !== undefined) {
                                                        if (_props.title !== undefined) 
                                                            _title = _props.title

                                                        if (_props.fieldTitle !== undefined) {
                                                            if (_props.fieldTitle)
                                                                _title = value     
                                                        }

                                                        if (_props.maxLength && value)
                                                            value = value.substr(0,_props.maxLength)
                                                    }
                                            
                                                    if (headerGrid[header].item?.switch?.titleField) 
                                                        _title = _data[headerGrid[header].item.switch.titleField]                                               

                                                    let _iconVisible = false
                                                    if (headerGrid[header].icon) {
                                                        _iconVisible = true
                                                        if (headerGrid[header].icon.visualConditional) { 
                                                            Object.keys(headerGrid[header].icon.visualConditional).map((_itemi,_keyi) => {
                                                                Object.values(headerGrid[header].icon.visualConditional).map(_value => {
                                                                    if (typeof _value.values === 'object') {
                                                                        _value.values.map(_valueCheck => {
                                                                            _iconVisible = _iconVisible && compare(_data[_itemi],_valueCheck,_value.operator)
                                                                        })
                                                                    } else 
                                                                        _iconVisible = _iconVisible && compare(_data[_itemi],_value.values,_value.operator)
                                                                })
                                                            })
                                                        }
                                                    }

                                                    if (callbackCellClick !== undefined) {
                                                        return (<td style={{cursor:"Pointer"}} 
                                                                    title={_title} 
                                                                    onClick={e => callbackCellClick(_data[primaryKey])} 
                                                                    key={99999 + keyheader} 
                                                                    className={headerGrid[header].item !== undefined ? headerGrid[header].item.className : ""}>
                                                                        {(_iconVisible)? 
                                                                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                                                                <div>
                                                                                    <span className={_class} style={{whiteSpace:"pre"}}>{value}</span>
                                                                                </div>
                                                                                <div title={headerGrid[header].icon.title}>
                                                                                    <i style={{color:`${headerGrid[header].icon.color}`}}className={headerGrid[header].icon.icon}></i>
                                                                                </div>
                                                                            </div>
                                                                        :
                                                                            <span className={_class} style={{whiteSpace:"pre"}}>{value}</span>}
                                                                </td>)
                                                    } else  
                                                        return (<td key={keyheader} 
                                                                    title={_title} 
                                                                    className={headerGrid[header].item !== undefined ? headerGrid[header].item.className : ""} >
                                                                        <span className={_class} style={{whiteSpace:"pre"}}>{value}</span>                                                                    
                                                                </td>)
                                                })}
                                            </tr>    
                                    )
                                })}
                            </tbody>
                        </table>
                    </CardBody>
                : 
                    <CardBody className={`no-border no-padding-hrz card-grid-box ${boxClassName !== undefined ? boxClassName : ''}` } style={{overflow:"auto", gap:"5px", display:"flex", flexWrap:"wrap"}}>
                       
                        {data.map((_data, keyData) =>   
                             getGridLayout(_data)
                        )}
                    </CardBody>
                }
                <div style={{display:"flex", flexDirection:"row", borderTop:"1px dashed #cacaca"}}>
                    <div style={{marginTop:"5px", width:"250px"}}>
                        <span style={{fontWeight:"400"}}>Listando {((page * getPerPage()) - (getPerPage() - 1))} à {(page * getPerPage()) > total ? total : (page * getPerPage())} de {total} registro(s)</span>
                    </div>
                    <div className="rodape-paginate">
                        <Pagination
                            activePage={page}
                            itemsCountPerPage={getPerPage()}
                            totalItemsCount={total}
                            pageRangeDisplayed={5}
                            onChange={handlePageChange}
                        />
                    </div>
                </div>
            </Card>
        ) 
          
    } else {
        if (data === undefined)
            return(
                <div className="box-not-register bg-danger" style={{ width: "100%", maxHeight: "83px" }}>
                    <em className="fa fa-frown" style={{ lineHeight: "27.33px" }}></em> <span style={{marginLeft:"10px"}}>Não foi possível listar os registros solicitados.</span>
                </div>)
        else
            return(
                <div className="box-not-register bg-warning" style={{ width: "100%", maxHeight: "83px" }}>
                    <em className="fa fa-frown" style={{ lineHeight: "27.33px", color:"#000"}}></em> <span style={{marginLeft:"10px", color:"#000"}}>Nenhum registro encontrado!</span>
                </div>    )
    }       
}