import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import { Button, Nav, NavLink, NavItem, TabPane, TabContent } from 'reactstrap';
import Swal from 'sweetalert2'

export default function Agreements() {
    const [loading, setLoading]                            = useState(false)
    const [tabActive, setTabActive]                        = useState(0)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])
    const [editPermission, setEditPermisson]               = useState(false)

    const getOccupation = async() => {
        let _delCheck  = !checkPermission(4,5)
        let _editCheck = !checkPermission(8,5)
        setEditPermisson(_editCheck)
        setGridParams({idoccupation     : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                        value : '00000'},
                                           primaryKey  : true                          },
                       occupation       : {text        : "Nome",
                                           searchDefault : true},
                       specialist       : {text        : "Especialista?"},
                       Options          : {text        : "Opções",
                                           className   : "text-center",
                                           order       : false,
                                           filter      : false,
                                           item        : [{type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Exclusão de cargo",
                                                                    className      : 'btn btn-sm btn-danger',
                                                                    icon           : "fa fa-trash",
                                                                    disabled       : _delCheck,
                                                                    message        : "Deseja realmente excluir o cargo?",
                                                                    indexcallback  : 0}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Editar cargo",
                                                                    disabled       : _editCheck,
                                                                    className      : 'btn btn-sm btn-warning',
                                                                    icon           : "fa fa-edit",
                                                                    indexcallback  : 1}}]}
                                             
                 })
    }

    useEffect(() => {
        getOccupation()
        setStatistic('maintenance\\occupation')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}

    return (    
        <ContentWrapper>
            <Loading loading={loading}/>

            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <div>
                    <div className="box-schedule-time">
                        <div style={{padding:"10px"}}>
                            <button className="btn btn-lg btn-success"><i className="fa fa-user-md"/> Iniciar Atendimento</button>
                        </div>
                        <div className="box-schedule-cron">
                             <i className="fa fa-clock"/>
                             <span> 00:12:22</span>   
                        </div>
                    </div>
                    <div>
                        <Nav tabs vertical>
                            <NavItem>
                                <NavLink className={tabActive === 0 ? "active" : ""} onClick={() => { setTabActive(0)}}>
                                    <span id="company-data">Resumo</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 1 ? "active" : ""} onClick={() => { setTabActive(1)}}>
                                    <span id="documents">Calendário de Vacinas</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 2 ? "active" : ""} onClick={() => { setTabActive(2)}}>
                                    <span id="cnaes">Exame Físico</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 3 ? "active" : ""} onClick={() => { setTabActive(3)}}>
                                    <span id="address-phones">Estátura</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 4 ? "active" : ""} onClick={() => { setTabActive(4)}}>
                                    <span id="fiscal">Condutas</span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={tabActive === 5 ? "active" : ""} onClick={() => { setTabActive(5)}}>
                                    <span id="other-contacts">Prescrições</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                </div> 
                <div>

                </div>
            </div>                    
        </ContentWrapper>
    )
}