import React, {useState, useEffect} from 'react'
import {  format, differenceInMinutes, addMinutes, addDays, isEqual, sub  } from 'date-fns' 
import { zonedTimeToUtc } from 'date-fns-tz';

export default function Calendar({configs, events, newEvent, editEvent, legend}) {
    const [calendars ,setCalendars] = useState([])
    const [maximized, setMaximized] = useState(false)

    const handleMaximized = (_id) => {
        let _aux = []
        setMaximized(!maximized)
        
        calendars.map((item) => {
            _aux.push({name    : item.name, 
                       visible : maximized ? true : item.name === _id ? true  : false
                       })
        })

        setCalendars([])
        setCalendars(_aux)
    }

    const Resource = ({title ,subtitle, interval, start, end, showMaximized, showWeek, events, id, idemployer}) => {
        let _data  = format(zonedTimeToUtc(new Date(),'America/Sao_Paulo'),'yyyy-MM-dd')
        let _now   = new Date(_data + ' ' + start)
        let _end   = new Date(_data + ' ' + end)
        let _hours = []

        if (_now > _end)
            _end = addDays(_end,1) 

        while (_now < _end) {
            let _filter = events.filter((item) => {
                let _filterNow = new Date(item.date + ' ' + item.hour)
                return(isEqual(_filterNow,_now))
            })

            _hours.push({hour  : _now,
                         event : _filter})

            _now = addMinutes(_now,interval)
        }

        let _dataEvent = {
            date : null,
            hour : null,
            idemployer 
        }

        return (<div className="calendar-res-box">
                    <div className="calendar-res-title">
                        <div className="calendar-res-title-B1">
                            <span className="calendar-res-span-title">{title}</span>
                            <span className="calendar-res-span-subtitle">{subtitle}</span>
                        </div>
                        <div className="calendar-res-title-button">
                            <button title="Novo Agendamento" onClick={e => newEvent(true,_dataEvent)}><i className="fa fa-calendar-plus"/></button>
                            {showMaximized ?
                                <button title="Expadir Agenda" onClick={e => handleMaximized(id)}><i className="fa fa-window-maximize"/></button>
                            : ''}
                            {showWeek ?
                                <button title="Visualização Semanal"><i className="fa fa-calendar-alt"/></button>
                            :
                              ''}                            
                        </div>
                    </div>
                    <div className="calendar-res-body">
                        {_hours.map((item, key) => {
                              let _checkNow = differenceInMinutes(new Date(),new Date(_data + ' ' + format(item.hour,'HH:mm'))) < interval &&
                                            differenceInMinutes(new Date(),new Date(_data + ' ' + format(item.hour,'HH:mm'))) > 0

                            return(<div id={`p${format(item.hour,'HHmm')}`} className={`calendar-res-events ${ _checkNow ? 'calendar-res-events-now' : ''}`} key={key}>
                                       <div className='calendar-res-hour'>
                                           <span>{format(item.hour,'HH:mm')}</span>
                                       </div>
                                       <div className='calendar-res-data' onClick={e => item.event.length > 0 ? (e) : newEvent(true,{date : _data, hour : format(item.hour,'HH:mm'), idemployer})}>
                                            {item.event.map((subitem, subkey) => {
                                                return(
                                                    <div style={{backgroundColor: `${subitem.color}`}} className='calendar-res-event' key={subkey} onClick={e => editEvent(subitem.id)}>
                                                        <div>
                                                            <span>{subitem.name}</span>
                                                        </div>
                                                        <div style={{display:"flex"}}>
                                                            {subitem.alerts ? 
                                                                subitem.alerts.map((item) => {
                                                                    return(<div>
                                                                                <button title={item.legend}><i className={item.icon}/></button>
                                                                           </div>)  
                                                                })
                                                            : ''}
                                                        </div>
                                                    </div>
                                                )
                                            })}                                    
                                       </div>
                                   </div>)
                        })}
                        
                    </div>
                    <div className="calendar-res-footer">

                    </div>
                </div>)
    }

    return(
        <div>
            <div className='calendar-res-content'>
                {configs.map((item, key) => {
                    let _filter = events.filter((_item) => {
                        return(_item.parent === item.id)
                    })
                    if (calendars.length < configs.length)
                        setCalendars(calendars => calendars.concat({
                            name    : 'cal_' + key, 
                            visible : true
                        }))

                    let _calendar = calendars.filter((item) => {return(item.name === 'cal_' + key)})

                    if (_calendar.length > 0)
                        _calendar = _calendar[0].visible

                    if (_calendar)
                        return(<Resource key={key} 
                                         idemployer={item.id}
                                         id={'cal_' + key}
                                         visible={_calendar}
                                         title={item.title} 
                                         subtitle={item.subtitle}
                                         interval={item.interval}
                                         start={item.start}
                                         end={item.end}
                                         showMaximized={item.showMaximized}
                                         showWeek={item.showWeek}
                                         events={_filter}/>)
                })
                }
            </div>
            <div className='box-legend-scheduling'>
                <div style={{display:"flex", alignItems:"center"}}>
                    <span style={{fontWeight:'bold'}}>Legenda:</span>
                </div>
    
                {legend.map((item,key) => {
                    return(
                        <div key={key} className="txt-legend" style={{backgroundColor:`${item.style.background}`}}>
                            <span>{item.name}</span>                
                        </div>    
                )})}
            </div>
        </div>
    )
}