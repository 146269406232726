import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalProcedure from '../modals/modal-procedure';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'
import Swal from 'sweetalert2'

export default function Procedures() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([{ field : 'active', key : '=', value : true}])

    const [actives,setActives]                             = useState(0)
    const [basicFilters, setBasicFilters]                  = useState([])
    const [editPermission, setEditPermisson]               = useState(false)

    const getProcedure = async() => {
        let _delCheck  = !checkPermission(4,11)
        let _editCheck = !checkPermission(8,11)
        setEditPermisson(_editCheck)

        setGridParams({idtuss           : {text       : "#ID", mask : { name  : 'leftSlice',
                                                                        value : '00000'},
                                           primaryKey  : true                          },
                       code             : {text        : "Código"},
                       terminology      : {text        : "Terminologia",
                                           searchDefault : true},
                       resolution       : {text        : "Resolução"},
                       group            : {text        : "Grupo"},
                       subgroup         : {text        : "Sub-Grupo"},
                       chapter          : {text        : "Capítulo"},

                       Options          : {text        : "Opções",
                                           className   : "text-center",
                                           order       : false,
                                           filter      : false,
                                           item        : [{type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Exclusão de Procedimento | TUSS",
                                                                    className      : 'btn btn-sm btn-danger',
                                                                    icon           : "fa fa-trash",
                                                                    disabled       : _delCheck,
                                                                    message        : "Deseja realmente excluir o procedimento?",
                                                                    indexcallback  : 0}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Editar Procedimento",
                                                                    disabled       : _editCheck,
                                                                    className      : 'btn btn-sm btn-warning',
                                                                    icon           : "fa fa-edit",
                                                                    indexcallback  : 1}}]}
                                             
                 })
    }

    useEffect(() => {
        getProcedure()
        setStatistic('maintenance\\procedure')
    },[])
    
    const handleCloseModal = () => {setShowModal(false)}
    const handleFilter     = () => {setIsOpenFilter(!isOpenFilter)}
    const handleFilterBasic = (value) => {
        setActives(value)
        if (value >= 0) {
            let filter = {field    : 'active',
                          key      : '=',
                          value    : value === 0 ? true : false}
            setBasicFilters([filter])
            setFilters(filters => filters.filter((item) => {
                                                               return item.field !== 'active'
                                                            }).concat(filter))
        } else {
            let filter = {}
            setBasicFilters([])
            setFilters(filters => filters.filter((item) => {
                            return item.field !== 'active'
                       }).concat(filter))
        }
        
    }

    const RemoveProcedure = async (id) => {  
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente excluir o procedimento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.delete('/procedure/' + id)
                         .then(response => {
                             ShowMessages(response.data)
                             setReload(true)
                         })
                         .catch( error => {
                             ShowMessages(error)
                         })
            }
            
        }) 
    }

    const EditProcedure= async(id) => {
        await api.get('/procedure/' + id)
                 .then(response => {
                    setData(response.data)
                    setEditState(true)
                    setShowModal(true)
                 })
                 .catch( error => {
                    ShowMessages(error)
                 })
    }

    return (    
        <ContentWrapper className='content-wrapper-no-overflow'>
            <Loading loading={loading}/>
            <ModalProcedure showModal={showModal} handleCloseModal = {handleCloseModal} editState={editState} data={data} handleReload={setReload}/>
            <div className={`container-lg container-100 ${(loading ? "hidden" : "")}`}>
                <div className="content-heading">
                    <div className="box-maintance-buttons">
                        <div>
                            <button className="btn btn-success" disabled={!checkPermission(2,11)} title="Insere um novo registro" onClick={e => {setEditState(false) 
                                                                                                                                                setShowModal(true)}}><i className="fa fa-plus"></i></button>
                            <button className="btn btn-primary" title="Atualizar Dados" style={{marginLeft:"3px"}} onClick={e => setReload(true)}><i className="fa fa-redo"></i></button>
                            {showButtons ? 
                                <>
                                    <button className="btn btn-dark" title="Impressão" onClick={e => setPrint(true)} style={{marginLeft:"3px"}}><i className="fa fa-print"></i></button>
                                    <button className="btn btn-info" disabled={!checkPermission(16,11)} title="Exportar para excel" onClick={e => setExcel(true)} style={{marginLeft:"3px"}}><i className="fa fa-file-excel">    </i></button>
                                    <button className="btn btn-secondary" title="Filtros Avançados" style={{marginLeft:"3px"}} onClick={e => handleFilter()}><i className="fa fa-search-plus"></i></button>
                                </>
                            : "" }
                        </div>
                    </div>
                    <div>
                        
                        <div className="box-basic-filter">
                            <label>Exibir somente</label>
                            <div style={{display:"flex", marginTop:"5px "}}>
                                <div className='box-radio'>
                                    <input type="radio" className="form-check-input" style={{marginLeft:"0px"}} checked={actives === 0} onChange={e => {handleFilterBasic(0)}}/>
                                    <span style={{marginLeft:"17px", marginTop:"4px"}}>Ativos</span>
                                </div>  
                                <div className='box-radio'>
                                    <input type="radio" className="form-check-input" style={{marginLeft:"0px"}} checked={actives === 1} onChange={e => {handleFilterBasic(1)}}/>
                                    <span style={{marginLeft:"17px", marginTop:"4px"}}>Inativos</span>
                                </div>  
                                <div className='box-radio'>
                                    <input type="radio" className="form-check-input" style={{marginLeft:"0px"}} checked={actives === -1} onChange={e => {handleFilterBasic(-1)}}/>
                                    <span style={{marginLeft:"17px", marginTop:"4px"}}>Todos</span>
                                </div> 
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={`content-box-cart ${(loading ? "hidden" : "")}`}>
                <Filter isOpenFilter={isOpenFilter} filters={gridParams} callback={setFilters} callBackOpenFilter={handleFilter} basicFilters = {basicFilters}/>
                <Grid url                 = '/procedure' 
                      headers             = {gridParams} 
                      loading             = {setLoading} 
                      reload              = {reload}
                      print               = {print}
                      excel               = {excel}
                      filters             = {filters}
                      filename            = "Procedimentos - TUSS"
                      callbackShowButtons = {setShowButtons}
                      callbackExcel       = {setExcel}
                      callbackPrint       = {setPrint}
                      callbackReload      = {setReload}
                      callbackCellClick   = {editPermission ? undefined : EditProcedure}
                      callbackButtons     = {[RemoveProcedure, 
                                              EditProcedure]}
                      />  
            </div>                    
        </ContentWrapper>
    )
}