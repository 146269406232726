import React, { useState, useEffect } from 'react'
import ContentWrapper from '../layouts/contentWrapper'
import Filter from '../../components/Filter';
import Loading from '../../components/Loading';
import ModalHealthInsurance from '../modals/modal-health-insurance';
import Grid from '../../components/Grid';
import api from '../../services/api'
import ShowMessages from '../../components/Exceptions'
import setStatistic from '../../services/apiStatistic'
import {checkPermission} from '../../components/security'

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default function NewsLetterSystem() {
    const [loading, setLoading]                            = useState(false)
    const [showModal, setShowModal]                        = useState(false)
    const [isOpenFilter, setIsOpenFilter]                  = useState(false)
    const [reload, setReload]                              = useState(false)
    const [print, setPrint]                                = useState(false)
    const [excel, setExcel]                                = useState(false)
    const [showButtons, setShowButtons]                    = useState(false)
    const [editState, setEditState]                        = useState(false)
    const [gridParams, setGridParams]                      = useState({})
    const [data, setData]                                  = useState({})
    const [filters, setFilters]                            = useState([])

    
    return (   
        <ContentWrapper className="content-wrapper-bottom100">
            <Loading loading={loading}/>
            <VerticalTimeline layout="1-column-left" className="vertical-timeline-one-column-left vertical-timeline-custom-line">
                <VerticalTimelineElement
                    className="vertical-timeline-element--work"
                    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
                    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                    icon={<i className="fa fa-history icon-timeline" />}>
                    <div>
                        <div className="header-title-box">
                            <div className="header-module-date">
                                <div className="bg-success">
                                    20/07/2021
                                </div> 
                            </div>
                            <div className="header-module-box">
                                <div>
                                    Financeiro
                                </div> 
                                <div>
                                    Contas a Pagar
                                </div> 
                            </div>
                            <div className="header-module-like">
                                <span>Gostou?</span>
                                <i className="fa fa-thumbs-up text-success"/>
                                <i className="fa fa-thumbs-down text-danger"/>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="box-body-title">
                                Novidades
                            </div>
                            <div className="box-body-text">
                                <span className="box-body-subtitle">E-Commerce</span>
                                <span className="box-body-item">1) Integração com a Shopee</span>
                                <span>Com a integração entre vhsys e Shopee, você amplia suas vendas online em uma das maiores plataformas do mercado de forma integrada à sua gestão, mantendo todas as informações em um só lugar. 
                                      A integração possibilita a exportação de produtos do vhsys para a Shopee e a importação automática dos pedidos da Shopee para o vhsys. Instale o app e aproveite!</span>
                            </div>
                            <div className="box-body-title" style={{marginTop:"25px"}}>
                                Melhorias
                            </div>
                            <div className="box-body-text">
                                <span className="box-body-subtitle">E-Commerce</span>
                                <span className="box-body-item">1) Integração com a Shopee</span>
                                <span>Com a integração entre vhsys e Shopee, você amplia suas vendas online em uma das maiores plataformas do mercado de forma integrada à sua gestão, mantendo todas as informações em um só lugar. 
                                      A integração possibilita a exportação de produtos do vhsys para a Shopee e a importação automática dos pedidos da Shopee para o vhsys. Instale o app e aproveite!</span>
                            </div>
                            <div className="box-body-title" style={{marginTop:"25px"}}>
                                Correções
                            </div>
                            <div className="box-body-text">
                                <span className="box-body-subtitle">E-Commerce</span>
                                <span className="box-body-item">1) Integração com a Shopee</span>
                                <span>Com a integração entre vhsys e Shopee, você amplia suas vendas online em uma das maiores plataformas do mercado de forma integrada à sua gestão, mantendo todas as informações em um só lugar. 
                                      A integração possibilita a exportação de produtos do vhsys para a Shopee e a importação automática dos pedidos da Shopee para o vhsys. Instale o app e aproveite!</span>
                            </div>
                        </div>
                    </div>
                </VerticalTimelineElement>
            </VerticalTimeline>      
        </ContentWrapper>
    )
}