import React, {  useState, useEffect } from 'react';
import { getUserData, IDSTATUS, ReadDataStorage, USERNAME } from './../../services/auth';
import Loading from '../../components/Loading';
import AutoScroll from '@brianmcallister/react-auto-scroll';
import AUDIO_CHAT  from './../../assets/audio/chat-alert.mp3'

export default function ChatContainer({object, props, closeCallback, socket}) {
    const [active, setActive]         = useState(true)
    const [loading, setLoading]       = useState(false) 
    const [message, setMessage]       = useState('')
    const [messages, setMessages]     = useState([])
    const [alert, setAlert]           = useState(0)

    const chat_alert = new Audio(AUDIO_CHAT)

    const alertChat = (showAlert) => {
        if ((alert === 0) && (showAlert)) {
            let _id = setInterval(function() {
                          document.querySelector('.chat-title').classList.toggle('div-alert-message')
                      },1000)
            setAlert(_id)
        } else if (alert !== 0){ 
            clearInterval(alert)
            setAlert(0)   
            document.querySelector('.chat-title').classList.remove('div-alert-message') 
        }    
    }

    const sendMessage = (e) => {
        if ((e === "Enter") && (message.trim() !== "")) {

            let _data = {message,
                         idstatus : parseInt(ReadDataStorage(IDSTATUS)) ,  
                         idsender : getUserData()['iduser'],
                         imgUrl   : getUserData()['pathphoto'],
                         name     : ReadDataStorage(USERNAME),
                         iduser   : props.id,
                         room     : 110}

           socket.emit('sendMessage',_data)
           setMessages(messages => messages.concat({he : message,status : 2, create_at : "2019-01-01 12:45:11"}))
           setMessage('')
        }
    }


    const receiveData = () => {
        if (props.messages.length > 0) {
            if (ReadDataStorage(IDSTATUS) !== "3")      
                chat_alert.play()
            setMessages(messages => messages.concat({me : props.messages[0].he,status : 2, create_at : "2019-01-01 12:45:11"})) 
        }

        socket.on("getMessages", data => {
            data.map((data) => {
                return(
                    setMessages(messages => messages.concat({me : data.message,status : 2, create_at : "2019-01-01 12:45:11"})) 
                )
            })         
        })

        socket.on("sendMessage", data => {
            if (ReadDataStorage(IDSTATUS) !== "3") { 
                chat_alert.play()
                alertChat(true)
                document.title = "(1) " + process.env.REACT_APP_NAME
            }
            setMessages(messages => messages.concat({me : data.message,status : 2, create_at : "2019-01-01 12:45:11"}))          
        })
    }

    useEffect(() => {
        receiveData()
    },[])

    useEffect(() => {

    },[alert])

    const statusToClass = (status) => {
        switch (status) {
            case 0:
                return 'bg-mutted'
            case 1:
                return 'bg-success'
            case 2:
                return 'bg-warning'  
            case 3:
                return 'bg-danger'                
            default:
                break;
        }
    }

    return (
        
        <div className={`box-message ${!active ? "chat-deactive" : ""}`}>
            <div className="chat-title" onClick={e=>setActive(!active)}>
                <div>
                    {props.imgUrl === "" ? <div className="avatar-no-photo-window"><span>{props.name.substr(0,1)}</span></div> : <img alt="" src={props.imgUrl}/>}
                    <span className={`circle ${statusToClass(props.status)} circle-md`}></span>
                </div>
                <div>
                    <span>{props.name.length > 25 ? props.name.substring(0,25) + '...' : props.name}</span>
                    {props.status === 1 ?  <span>Online agora</span> :""}
                </div>
                <div>
                    <em className="fa fa-times-circle" onClick={e=>closeCallback(e,props.id,object)}/>
                </div>
            </div>
            <AutoScroll className="chat-messages" showOption={false}>
                <Loading loading={loading}/>
                {messages.map((message, key) => {
                    if (message.me)
                        return(<div key={key} className="chat-message-me"><span className="chat-span chat-me">{message.me}</span></div>)
                    else  
                        return(<div key={key} className="chat-message-he">{props.imgUrl === "" ? <div className="avatar-no-photo-window" style={{marginRight:"10px", marginLeft:"0px"}}><span>{props.name.substr(0,1)}</span></div> : <img alt="" className="chat-img-he" src={props.imgUrl}/>}<span className="chat-span chat-he">{message.he}</span></div>)   
                })}
            </AutoScroll>
            <div className="chat-option">
                <div>
                </div>
                <div>
                   <input placeholder="Digite sua mensagem..." autoFocus value={message} onChange={e => {setMessage(e.target.value);
                                                                                                         alertChat(false)}} onKeyDown={e => sendMessage(e.key)}/>
                   <em className="fa fa-paperclip" title="Clique aqui para enviar um arquivo"/>
                </div>
                </div>
            </div> 
        );
  

}

