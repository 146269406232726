import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button,  } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';

import api from '../../services/api';
import ShowMessages from '../../components/Exceptions'
import Swal from 'sweetalert2'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalSchedulingOptions({showModal, handleCloseModal, data, handleReload, handleReScheduling}) {
    const [loading, setLoading]                   = useState(false)

    const [patient, setPatient]                   = useState('')
    const [phone, setPhone]                       = useState('')
    const [healthinsurance, setHealthInsurance]   = useState('')
    const [idScheduling, setIdscheduling]         = useState(0)
    const [idAttendanceflux, setIdAttendanceflux] = useState(0)

    const [urlGuia, setUrlGuia]                   = useState('') 
    const [nextFlux, setNextFlux]                 = useState(0)

    const cancelScheduling = async() => {
        Swal.fire({
            icon: 'warning',
            title: 'Deseja realmente cancelar o agendamento?',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sim',
            confirmButton: 'bg-theme',
            denyButtonText: `Não`,
          }).then(async(result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                await api.post('/schedule/cancel/' + idScheduling)
                         .then(response => {
                            handleReload(true)
                            handleCloseModal()
                            ShowMessages(response)
                          })
                          .catch( error => {
                                ShowMessages(error)
                            })
            }
        })
    }

    const reschedule = () => {
        handleReScheduling(idAttendanceflux)
    }

    const generateGuide = () => {
        window.open(urlGuia,'_blank');
        window.open(urlGuia);
    }

    const startService = (name) => {
        let props = {
            id : 1 
        }
        if (document.querySelector('body').classList.length > 0)
            document.querySelector('body').classList.toggle('aside-collapsed')
        window.addTab(null, 'Atendimento | ' + patient, 'fa fa-stethoscope', '/medical-care',props,0)
        handleCloseModal()    
    }

    const setStatus = () => {
        alert('reagendar')
    }

    const receivement = () => {
        alert('reagendar')
    }

    const handleSubmit = () => {

    }

    const getData = () => {
        setNextFlux(data.nextflux)
        setPatient(data.people_scheduling.name)
        setPhone(data.people_scheduling.phone)
        setHealthInsurance(data.healthinsurance)
        setIdscheduling(data.idscheduling)
        setIdAttendanceflux(data.idattendanceflux)
        setUrlGuia(data.urlguia)
        setLoading(false)
    }

    useEffect(() => {
        if (data !== null)
            getData()
    },[data])

    useEffect(() => {
        if (showModal)
            setLoading(true)
    },[showModal])

    return (
        <Modal size='xl' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogClassName='width550' dialogAs={DraggableModalDialog}>
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-calendar-check"></i> Painel do Paciente
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass='heigth250px'/>
                    <div className={`${(loading ? "hidden" : "")}`}>
                        <div>
                            <div>
                                <label>Nome</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="patient"
                                        id="patienat"
                                        type="text"
                                        disabled="disabled"
                                        value={patient}
                                        className="form-control "/>
                                </div>
                            </div>   
                            <div>
                                <label>Telefone</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="phone"
                                        id="phone"
                                        type="text"
                                        disabled="disabled"
                                        value={phone}
                                        className="form-control "/>
                                </div>
                            </div>  
                            <div>
                                <label>Convênio</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="healthinsurance"
                                        id="healthinsurance"
                                        type="text"
                                        disabled="disabled"
                                        value={healthinsurance}
                                        className="form-control "/>
                                </div>
                            </div>   
                            <div style={{display:"flex"}}>
                                <div>
                                    <label>Tipo de Consulta</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="occupation"
                                            id="occupation"
                                            type="text"
                                            required
                                            autoFocus={true}
                                            maxLength={100}
                                            errorMessage={{ required: "Por favor, informe um cargo!" }}
                                            className="form-control "
                                            autoComplete="off"/>
                                    </div>
                                </div>
                                <div style={{marginLeft:"15px"}}>
                                    <label>Data da Ultima Consulta</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="occupation"
                                            id="occupation"
                                            type="text"
                                            required
                                            autoFocus={true}
                                            maxLength={100}
                                            errorMessage={{ required: "Por favor, informe um cargo!" }}
                                            className="form-control "
                                            autoComplete="off"/>
                                    </div>
                                </div> 
                                <div style={{marginLeft:"15px"}}>
                                    <label>Ritmo de Atendimento</label>
                                    <div className="input-group">
                                        <TextInput  
                                            name="occupation"
                                            id="occupation"
                                            type="text"
                                            required
                                            autoFocus={true}
                                            maxLength={100}
                                            errorMessage={{ required: "Por favor, informe um cargo!" }}
                                            className="form-control "
                                            autoComplete="off"/>
                                    </div>
                                </div>         
                            </div>
                        </div>
                        {(![2].includes(idAttendanceflux)) ?
                            <><div style={{display:"flex", marginTop:"15px", flexWrap:'wrap', borderTop:"1px dashed #cacaca"}}>
                                <button type="button" className="btn btn-danger btn-settings-scheduling"    style={{marginTop:"10px",height:"auto"}}                  onClick={e => cancelScheduling()}><i className="fa fa-ban"/> Cancelar</button>
                                <button type="button" className="btn btn-warning btn-settings-scheduling"   style={{marginTop:"10px",marginLeft:"3px",height:"auto"}} onClick={e => reschedule()}><i className="fa fa-retweet"/> Reagendar</button>
                                {urlGuia !== '' ?
                                    <button type="button" className="btn btn-primary btn-settings-scheduling"   style={{marginTop:"10px",marginLeft:"3px",height:"auto"}}  onClick={e => generateGuide()}><i className="fa fa-file-contract" /> Gerar Guia</button>
                                : ''}
                                <button type="button" className="btn btn-info btn-settings-scheduling"      style={{marginTop:"3px",height:"auto"}} onClick={e => receivement()}><i className="fa fa-file-invoice-dollar" /> Recebimento</button>
                                <button type="button" className="btn btn-secondary btn-settings-scheduling" style={{marginTop:"3px",marginLeft:"3px", height:"auto"}} onClick={e => setStatus()}><i className="fa fa-sign-out-alt"/> Status</button>
                                <button type="button" className="btn btn-success btn-settings-scheduling"   style={{marginTop:"3px",marginLeft:"3px",height:"auto"}} onClick={e => startService()}><i className="fa fa-user-md"/> Atender Agora</button>
                                </div>
                            </>
                        : ''}
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>        
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}