import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation';
import { Button } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import ShowMessages from '../../components/Exceptions'
import Loading from '../../components/Loading';
import Draggable from 'react-draggable';

import Grid from '../../components/Grid';
import api from '../../services/api';
import setStatistic from '../../services/apiStatistic'
import Swal from 'sweetalert2'

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalWaitingList({showModal, handleCloseModal, handleReload}) {
    const [loading, setLoading]       = useState(false)
    const [gridParams, setGridParams] = useState({})
    const [reload, setReload]         = useState(false)

    const CancelWaiting = async(id) => {
        Swal.fire({
            title: 'Confirmação',
            text: "Deseja realmente cancelar o agendamento?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then(async(result) => {
            if (result.isConfirmed) {
                await api.post('/schedule/cancel/' + id)
                         .then(response => {
                            setReload(true)
                            ShowMessages(response)
                         })
                         .catch( error => {
                            ShowMessages(error)
                         })
            }
            
        }) 
    }

    
    const getWaitingList = () => {
        let _delCheck  = false
        let _editCheck = false
        setGridParams({idscheduling   : {text          : "#ID", mask : { name  : 'leftSlice',
                                                                         value : '00000'},
                                         primaryKey    : true,
                                         visible       : false},          
                       order          : {text          : "Ordem" },
                       create_at      : {text          : "Data da Solicitação" },
                       name           : {text          : "Nome",
                                         searchDefault : true},
                       phone          : {text          : "Telefone"},
                       specialist     : {text          : "Especialista"},
                       Options        : {text          : "Opções",
                                         className     : "text-center",
                                         order         : false,
                                         filter        : false,
                                         item          : [{type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Cancelamento de Paciente em Espera",
                                                                    className      : 'btn btn-sm btn-danger',
                                                                    icon           : "fa fa-ban",
                                                                    disabled       : _delCheck,
                                                                    indexcallback  : 0}},
                                                          {type  : "Custom",
                                                           props : {tag            : "button",
                                                                    title          : "Agendar Paciente",
                                                                    className      : 'btn btn-sm btn-warning',
                                                                    icon           : "fa fa-edit",
                                                                    disabled       : _editCheck,
                                                                    indexcallback  : 1}}]}

        })
    }

    useEffect(() => {
        getWaitingList()
        setStatistic('modals\\modal-waiting-list')
    } ,[])

    return (
        <Modal size='lg' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static"  dialogAs={DraggableModalDialog}>
            <ValidationForm  setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-user-tie"></i> Lista de Espera
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body style={{padding:"0px 20px"}}>
                    <Loading loading={loading} addClass="heigth350px"/>
                    <div className={`${loading ? "hidden" : ""}`}>     
                        <Grid url                 = '/getwaitinglist' 
                              headers             = {gridParams} 
                              loading             = {setLoading} 
                              perPage             = {5}
                              reload              = {reload}
                             // print               = {print}
                              //excel               = {excel}
                              filters               = ''
                              //callbackShowButtons = {setShowButtons}
                              //callbackPrint       = {setPrint}
                              callbackReload      = {setReload}
                              //callbackExcel       = {setExcel}
                              //filename            = "Convênio"
                              paginate            = {false}
                              //callbackCellClick   = {editPermission ? undefined : EditHealthInsurance}
                               callbackButtons    = {[CancelWaiting]}
                      />                                
                    </div>
                </Modal.Body>
                <Modal.Footer>         
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}