import React, {useState, useEffect} from 'react'
import { Card, CardBody, Collapse } from 'reactstrap';

export default function Filter({isOpenFilter, filters, callback, callBackOpenFilter, basicFilters,externalFilters}) {
    const [field, setField] = useState([])
    const [keys, setKeys]   = useState([])
    const [types, setTypes] = useState([])

    const [selectField, setSelectField]      = useState('')
    const [selectKey, setSelectKey]          = useState('%%')
    const [value, setValue]                  = useState('')
    const [value2, setValue2]                = useState('')
    const [ignoretime, setIgnoreTime]        = useState(false)
    const [collect, setCollect]              = useState(false)
    const [selectFilters, setSelectFilters ] = useState([])
    const [inputType, setInputType]          = useState('text')
    const [searchFields, setSearchFields]    = useState([])

    const [searchType, setSearchType]        = useState(0)

    const _arSearch = [{"text"  : "Começa com...",
                        "value" : "%..",
                        "types" : ['string']},

                       {"text"  : "Contenha...",
                        "value" : "%%",
                        "types" : ['string']},

                       {"text"  : "Maior que...",
                        "value" : ">",
                        "types" : ['string','number','date']},

                       {"text"  : "Maior e igual a...",
                        "value" : ">=",
                        "types" : ['string','number','date']},

                       {"text"  : "Menor que...",
                        "value" : "<",
                        "types" : ['string','number','date']},

                       {"text"  : "Menor e igual a...",
                        "value" : "<=",
                        "types" : ['string','number','date']},

                       {"text"  : "Igual a...",
                        "value" : "=",
                        "types" : ['string','number','date','boolean']},

                       {"text"  : "Diferente de...",
                        "value" : "<>",
                        "types" : ['string','number','date','boolean']},

                       {"text"  : "Termina com..",
                        "value" : "..%",
                        "types" : ['string']},
                                                
                       {"text"  : "Entre...",
                        "value" : "between",
                        "types" : ['number','date']}]

    // 0 : geral
    // 1 : between
    // 2 : boolean

    const handleSearch = (type) => {
        setSearchFields([])
        _arSearch.map((field) => {
            if (field.types.includes(type))
                setSearchFields(searchFields => searchFields.concat(field))
        })
    }

    useEffect(() => {
        Object.values(filters).map((filter, key) => {
            if ((filter.filter === undefined) || (filter.filter)) {
                setTypes(types => types.concat(filter.dataType === undefined ? '' : filter.dataType))
                setField(field => field.concat(filter))
                setKeys(keys   => keys.concat(Object.keys(filters)[key]))
                
                if (filter.searchDefault)
                    if (filter.searchDefault === true) {
                        handleSelectField(Object.keys(filters)[key])
                        handleSearch(Object.values(filters)[key].dataType === undefined ? 'string' : Object.values(filters)[key].dataType)
                    }

                if (filter.ignoretime)
                    if (filter.ignoretime === true)
                        setIgnoreTime(true)
            }
        })
    },[filters])

    const handleSelectKey = (_key) => {
        setSelectKey(_key)
        setSearchType(_key === 'between' ? 1 : searchType)
    }

    const handleSelectField = (_value) => {
        setSelectField(_value)
        setSearchType(0)
        setValue('')

        let _index = keys.indexOf(_value)
        if (types[_index] != undefined) {
            if (types[_index] === 'date') {
                setInputType('date')
                handleSearch('date')
                handleSelectKey('=')
            } else if (types[_index] === 'number') {
                setInputType('number')
                handleSearch('number')
            } else if (types[_index] === 'boolean') {
                setInputType('text')
                setValue('true')
                handleSearch('boolean')
                handleSelectKey('=')
                setSearchType(2)
            } else {
                setInputType('text')
                handleSearch('string')
            }
        }
    }

    const handleFilter = () => {
        let _filters    = { field  : selectField,
                            key    : selectKey,  
                            value  : value,
                            value2 : value2,
                            ignoretime   
                          }

        let _auxFilters = []                

        if (!collect) 
            setSelectFilters([])
        else 
            _auxFilters = _auxFilters.concat(selectFilters)
        
        if (externalFilters != null)    
            _auxFilters = _auxFilters.concat(externalFilters)                

        if (basicFilters != null)
            if (Object.keys(basicFilters).length > 0)
                _auxFilters = _auxFilters.concat(basicFilters)               


        if (!selectFilters.includes(_filters)) {
            _auxFilters = _auxFilters.concat(_filters) 
            setSelectFilters(selectFilters => selectFilters.concat(_filters))  
        } 

        callback(_auxFilters)              
    }

    const handleRemoveFilter = () => {
        if (externalFilters != null)  {   
            setSelectFilters(externalFilters)
            callback(externalFilters) 
        } else {
            setSelectFilters([])
            callback('') 
        }
        
    }

    return (
        <Collapse  style={{width:"336px",marginRight:"10px", marginTop:"20px"}} isOpen={isOpenFilter}>
            <Card>
                <CardBody>
                    <a href="/#" className="button-close" onClick={e => callBackOpenFilter()} title="Fechar painel de filtros"><em className="fa fa-times"></em></a>
                    <div>
                        <div>
                            <label>Qual campo?</label>
                            <select className="form-select" placeholder="Selecione" value={selectField} onChange={e => handleSelectField(e.target.value)}>
                                {field.map((field, key) => {
                                    return (<option key={key} value={keys[key]}>{field.text}</option>)
                                })}
                            </select>
                        </div>
                        <div>
                            <label>Como pesquisar?</label>
                            <select className="form-select" defaultValue={selectKey} onChange={e => handleSelectKey(e.target.value)}>
                                {searchFields.map((field, key) => {
                                    return (<option key={key} value={field.value}>{field.text}</option>)
                                })}
                            </select>
                        </div>
                        <div>
                            <label>O que pesquisar?</label>
                            {searchType === 0 ? <div>
                                                    <input type={inputType} className="form-control" value={value}  onChange={e => setValue(e.target.value)}/> 
                                                </div>
                                              : searchType ===  1 ? <div style={{display:"flex",flexWrap:"wrap",flex: "1 1 auto", height: "80px", justifyContent:'center', marginBottom:"10px"}}>
                                                                        <input type={inputType} className="form-control" style={{width:50 + "%"}} value={value}  onChange={e => setValue(e.target.value)}/> 
                                                                        <span style={{padding:"2px"}}>até</span>
                                                                        <input type={inputType} className="form-control" style={{width:50 + "%"}} value={value2} onChange={e => setValue2(e.target.value)}/> 
                                                                    </div>
                                                                  : <div style={{display:"flex", padding:"7px", justifyContent:"center"}}>
                                                                        <div style={{width:50 + "%", display:"flex", justifyContent:"center"}}>
                                                                            <input type='radio' className="form-check-input" checked={value === 'true'} value={value}  onChange={e => setValue('true')}/>
                                                                            <span style={{marginLeft:"22px"}}>Sim</span>
                                                                        </div>
                                                                        <div style={{width:50 + "%", display:"flex", justifyContent:"center"}}>
                                                                            <input type='radio' className="form-check-input" checked={value === 'false'} value={value}  onChange={e => setValue('false')}/>
                                                                            <span style={{marginLeft:"22px"}}>Não</span>
                                                                        </div>    
                                                                    </div> 
                            }   
                        </div>
                        <div style={{display:"flex"}}>
                            <input type="checkbox" className="form-check-input" style={{marginLeft:"0px"}} checked={collect} onChange={e => {setCollect(!collect)}}/>
                            <span style={{marginLeft:"17px"}}>Acumular pesquisas?</span>
                        </div>
                        <div style={{display:"flex", marginTop:"3px"}}>
                            <button type="button" className="btn btn-success" style={{ width:"120px"}} onClick={e => {handleFilter()}}><em className="fa fa-search-plus"></em> Filtrar</button>
                            <button type="button" className="btn btn-warning" style={{marginLeft:"3px", width:"120px" }} onClick={e => {handleRemoveFilter()}}><em className="fa fa-search-minus"></em> Retirar Filtro</button>
                        </div>
                    </div>
                </CardBody>
           </Card>
        </Collapse>
    )
}