import React, {useState, useEffect} from 'react'

import { ValidationForm, TextInput, SelectGroup} from 'react-bootstrap4-form-validation';
import { Button,  } from 'reactstrap';
import { Modal, ModalDialog } from 'react-bootstrap';
import Draggable from 'react-draggable';
import Loading from '../../components/Loading';
//import { Async } from "react-select-virtualized";
import api from '../../services/api';
import ShowMessages from '../../components/Exceptions'
import  loadingButton  from './../layouts/Buttons'
import MaskWithValidation from './../../components/maskInput'
import {  format } from 'date-fns' 

import Autocomplete from 'react-autocomplete';

class DraggableModalDialog extends React.Component {
	render() {
		return <Draggable handle=".modal-header"><ModalDialog {...this.props} /></Draggable>
	}
}

export default function ModalScheduling({showModal, handleCloseModal,editState, data, handleReload}) {
    const [loading, setLoading]                         = useState(false)

    const [patient, setPatient]                         = useState('')
    const [tuss, setTuss]                               = useState(null)
    const [phone, setPhone]                             = useState('')
    const [healthInsurance, setHealtInsurance]          = useState('')
    const [date, setDate]                               = useState(null)
    const [time, setTime]                               = useState(null)
    const [idemployer, setIdemployer]                   = useState(null)
    const [telemedicine, setTelemedicine]               = useState(false)
    const [type, setType]                               = useState(0)
    const [waitinglist, setWaitingList]                 = useState(false)

    const [dataTuss,setDataTuss]                        = useState([])
    const [dataHealthInsurance, setDataHealthInsurance] = useState([]) 
    const [dataProfessional, setDataProfessional]       = useState([])
    const [patients, setPatients]                       = useState([])

    const removeAcento = (text) => {       
        text = text.toLowerCase();                                                         
        text = text.replace(new RegExp('[ÁÀÂÃ]','gi'), 'a');
        text = text.replace(new RegExp('[ÉÈÊ]','gi'), 'e');
        text = text.replace(new RegExp('[ÍÌÎ]','gi'), 'i');
        text = text.replace(new RegExp('[ÓÒÔÕ]','gi'), 'o');
        text = text.replace(new RegExp('[ÚÙÛ]','gi'), 'u');
        return text;                 
    }

    const setData = (obj) => {
        setPatient(editState ? obj.people_scheduling.name : '')
        setTuss(editState ? obj.tuss : null)
        setPhone(editState ? obj.people_scheduling.phone : '')
        setHealtInsurance(editState ? obj.idhealthinsurance : '')
        setTelemedicine(editState ? obj.people_scheduling.telemedicine : false)
        setWaitingList(editState ? obj.waitinglist : false)
        setType((editState ? obj.people_scheduling.type : ''))
        setDate(editState ? format(new Date(obj.scheduling),'yyyy-MM-dd') : obj.date) 
        setTime(editState ? format(new Date(obj.scheduling),'HH:mm') : obj.hour)  
        setIdemployer(editState ? obj.idprofessional : obj.idemployer)    
    }
    
    const getData = async() => {
        setLoading(true)
        try {
            await api.get('/getScheduleData')
                     .then(response => {
                        if (response.ok) {
                            setDataTuss(response.data.tuss)
                            setDataHealthInsurance(response.data.healthInsurance)
                            setDataProfessional(response.data.professional)
                            setData(data,!editState)
                            setLoading(false)  
                        }
                     })
            
                      
        } catch (response) {
            setLoading(false)  
            ShowMessages(response)    
        }
    }

    const loadOptions = (input, callback) => {
        setTimeout(() => {
          callback(dataTuss.filter(({ label }) => removeAcento(label.toLowerCase()).includes(input.toLowerCase())));
        }, 200);
    };

    const handleSubmit = async(event) => {
        loadingButton(true,document.querySelector('#submit-scheduling'),document.querySelector('#submit-scheduling').children[0].classList,document.querySelector('#submit-scheduling').lastChild.nodeValue)
        event.preventDefault()
        try {
            let _data = { patient,
                          tuss,
                          phone,
                          date,
                          time,
                          telemedicine,
                          idemployer,
                          waitinglist,
                          type,
                          healthInsurance }
            if (editState) {
                await api.put('/schedule/' + data.idscheduling, _data)
                         .then(response => {
                            ShowMessages(response)  
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))         
                            } else 
                                loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))
                            ShowMessages(error) 
                        })
            } else {
                await api.post('/schedule', _data)
                         .then(response => {
                            ShowMessages(response)  
                            if (response.ok) {  
                                if (response.data.status === 200) {
                                    handleCloseModal()  
                                    handleReload(true)    
                                } else 
                                    loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))         
                            } else 
                                loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))
                        })
                        .catch( error => {
                            loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))
                            ShowMessages(error) 
                        })
            }

        } catch (response) {
            loadingButton(false,document.querySelector('#submit-scheduling','fa fa-save','Agendar'))
            ShowMessages(response)
        }
    }

    useEffect(() => {
        getData()
    },[])

    useEffect(() => {
        console.log(telemedicine)
    },[telemedicine])

    useEffect(() => {
        if (editState)
            setData(data)
        else
            setData({idemployer : '',
                     hour : '',
                     date : ''})
    },[showModal])

    const getItemValue = (item) => {
        if (item.phones !== null)
            setPhone(item.phones)
        return item.label;
    }

    const renderItem = (item, isHighlighted) => {
        return (
            <div style={{ background: isHighlighted ? 'lightgray' : 'white' , padding:"8px 5px"}}>
                {`${item.label} - ${item.phones}`}
            </div>   
        ); 
    }

    const retrieveDataAsynchronously = async(searchText) => {
        if ((searchText !== '') && (searchText.length > 3))
            await api.get('/searchPatient/' + searchText)
                     .then(response => {
                        setPatients(response.data)
                     })
                     .catch( error => { 
                        ShowMessages(error)
                     })
    }

    const onChange = (e) => {
        setPatient(e.target.value);
        retrieveDataAsynchronously(e.target.value);
    }

    const renderInput = (props) => {
        return <input {...props} className='form-control'/>
    }

    const handleWaitingList = (value) => {
        if (value) {
            setDate('')
            setTime('')
        }
        setWaitingList(value)

    }

    return (
        <Modal size='md' show={showModal} onHide={handleCloseModal} animation={true} backdrop="static" dialogAs={DraggableModalDialog} dialogClassName="width450 height450" >
            <ValidationForm onSubmit={handleSubmit} setFocusOnError={true} className="modal-content"> 
                <Modal.Header closeButton>
                    <Modal.Title style={{marginBottom:"0px"}}>
                        <i className="fa fa-calendar-check"></i> Agendamento de Paciente
                    </Modal.Title>
                </Modal.Header>
            
                <Modal.Body>
                    <Loading loading={loading} addClass="heigth330px"/>
                    <div style={{display:"flex",flexDirection:"column"}} className={`${(loading ? "hidden" : "")}`}>
                        <div style={{display:"flex", width:"100%"}}>
                            <div style={{width:"170px"}}>
                                <label>Data</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="date"
                                        id="date"
                                        type="date"
                                        required
                                        value={date}
                                        disabled={waitinglist}
                                        onChange={e => setDate(e.target.value)}
                                        autoFocus={true}
                                        errorMessage={{ required: "Por favor, informe a data!" }}
                                        className="form-control "
                                        autoComplete="off"/>
                                </div>
                            </div> 
                            <div style={{marginLeft:"15px",  width:"115px"}}>
                                <label>Hora</label>
                                <div className="input-group">
                                    <TextInput  
                                        name="hour"
                                        id="hour"
                                        type="time"
                                        value={time}
                                        disabled={waitinglist}
                                        onChange={e => setTime(e.target.value)}
                                        required
                                        style={{paddingTop: "2px", paddingBottom: "2px"}}
                                        errorMessage={{ required: "Por favor, informe a hora!" }}
                                        className="form-control "
                                        autoComplete="off"/>
                                </div>
                            </div> 
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "15px", width:'100px'}}>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" defaultChecked={waitinglist} value={waitinglist} onClick={e => handleWaitingList(!waitinglist)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                                <label style={{marginLeft: "15px"}}>Lista de Espera?</label>
                            </div>
                        </div>
                        
                        <div style={{display:"flex"}}>
                            <div style={{ width:"100%"}}>
                                <label>Profissional</label>
                                <SelectGroup 
                                    name="idemployer" 
                                    id="idemployer" 
                                    placeholder="Selecione" 
                                    className="form-select"
                                    value={idemployer} 
                                    onChange={e => setIdemployer(parseInt(e.target.value))}>
                                        <option value="">Selecione</option>
                                        {dataProfessional.map((data, id) => {
                                            return (<option key={id} value={data.value}>{data.label}</option>)
                                        })} 
                                </SelectGroup>                                                                                                  
                            </div>
                            <div style={{marginLeft:"15px", marginTop:"10px"}}>
                                <label>Telemedicina?</label>
                                <label className="switch switch-sm" style={{ justifyContent: "center" }}>
                                    <input type="checkbox" checked={telemedicine} defaultChecked={telemedicine} value={telemedicine} onClick={e => setTelemedicine(!telemedicine)}  />
                                    <span style={{ marginTop: "5px" }}></span>
                                </label>
                            </div>
                        </div> 
                        <div style={{width:"100%"}}>
                            <div>
                                <label>Paciente</label>
                                <div className="input-group">
                                    <Autocomplete 
                                        className="form-control "
                                        getItemValue={getItemValue}
                                        items={patients}             
                                        renderItem={renderItem}
                                        value={patient}
                                        renderInput={renderInput}
                                        onChange={onChange}
                                        onSelect={setPatient}
                                        wrapperStyle={{display:"flex",
                                                       flexDirection:"column",
                                                       width:"100%"}}
                                        menuStyle={{top : "auto",
                                                    left : "auto",
                                                    border:"1px solid #cacaca",
                                                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                                    borderRadius:"3px",
                                                    background: 'rgba(255, 255, 255, 0.9)',
                                                    padding: '2px 0',
                                                    fontSize: '90%',
                                                    overflow: "auto",
                                                    float: "left",
                                                    zIndex: 1000,
                                                    position: "fixed",
                                                    marginTop: "34px"}}
                                        required/>
                                </div>
                            </div> 
                        </div>
                        <div style={{ width:"100%"}}>
                            <label>Procedimento</label>
                            {/* <Async options={dataTuss} ignoreCase ignoreAccents value={tuss} loadOptions={loadOptions}  onChange={value => setTuss(value === null ? 0 : value)} placeholder="Digite para pesquisar..." noOptionsMessage={() => 'Não encontrado'}/>                                                                                                      */}
                        </div> 
                        <div style={{display:"flex",  width:"100%"}}>
                            <div style={{ width:"50%"}}>
                                <label>Celular</label>
                                <div className="input-group">
                                    <MaskWithValidation  
                                        name="phone"
                                        id="phone"
                                        type="text"
                                        required
                                        value={phone}
                                        className="form-control "
                                        onChange={e => setPhone(e.target.value)}
                                        mask={['(',/[0-9]/,/[0-9]/,')',' ',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/,'-',/[0-9]/,/[0-9]/,/[0-9]/,/[0-9]/]}/> 
                                </div>
                            </div> 
                            <div style={{ width:"50%", justifyContent:"center",alignItems:"center",display:"flex", marginLeft:"15px"}}>
                                {type === 0 ?        
                                    <div onClick={e => setType(type == 0 ? 1 : 0)} className="bg-success" style={{padding:"10px",borderRadius:"5px", width:"100%", marginTop:'10px', textAlign:"center", cursor:"pointer"}}>
                                        <span>Consulta | Exame</span>
                                    </div>
                                :     
                                    <div onClick={e => setType(type == 0 ? 1 : 0)} className="bg-info" style={{padding:"10px",borderRadius:"5px", width:"100%", marginTop:'10px', textAlign:"center", cursor:"pointer"}}>
                                        <span>Retorno</span>
                                    </div>
                                }
                            </div> 
                        </div> 
                        <div style={{display:"flex", flexDirection:"column", width:"100%"}}>
                            <div>
                                <label>Convênio</label>
                                <select
                                    id="healthinsurance"
                                    name="healthinsurance"
                                    required
                                    className="form-select"
                                    value={healthInsurance} 
                                    onChange={e => setHealtInsurance(e.target.value)}>
                                    <option value="">Selecione</option>
                                    {dataHealthInsurance.map((data, key) => {
                                        return (<option key={key} value={data.value}>{data.label}</option>)
                                    })}
                                </select>
                            </div> 
                        </div>     
                    </div>
                </Modal.Body>
                <Modal.Footer>  
                    <Button type="submit" id="submit-scheduling" className={`btn-theme ${loading ? "hidden" : ""}`}>
                        <em className="fa fa-save"></em> Agendar
                    </Button>          
                    <Button className="btn-ligth-gray" onClick={handleCloseModal}>
                        <em className="fa fa-door-closed"></em> Fechar
                    </Button>
                </Modal.Footer>
            </ValidationForm> 
        </Modal>
    )
}